import React, { useEffect, useState } from 'react';
import { Button, Grid, Link } from '@mui/material';
import _ from 'lodash';
import './entryFormAgreement.css';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setAgreeToAgreementDate, setEntries } from '../../../redux/reducers/entryFormsSlice';
import LaunchIcon from '@mui/icons-material/Launch';
import axios from 'axios';
import { EntryFormRecordData } from '../../../models/EntryFormRecordData';

export default function EntryFormAgreement() {

  const { agreeToAgreementDate, entries } = useAppSelector((state: any) => state.entryForms);
  const dispatch = useAppDispatch();

  const [ agreementText, setAgreementText ] = useState('');
  const [ isLoading, setIsLoading ] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const akcAgreementRsp: any[] = await axios.get(`/api/constants/akc-agreement`);
        setAgreementText(_.get(akcAgreementRsp, 'data.text', ''));
        setIsLoading(false);
      } catch(err: any) {
        console.log('get constants akc-agreement err=' + JSON.stringify(err, null, 2));
      }
    }
    fetchData();
  }, []);

  const handleClickIAgree = () => {
    const newDate = new Date().toISOString();
    dispatch(setAgreeToAgreementDate(newDate));
    const updatedEntries = _.cloneDeep(entries);
    updatedEntries.forEach((entry: EntryFormRecordData) => {
      entry.agreeToAgreementDate = newDate;
    });
    dispatch(setEntries(updatedEntries));
  }

  return (
    <Grid container justifyContent="center">
      { isLoading ? 
        <h1>Loading</h1>
        :
        <>
          <Grid item md={8}>
            <div id="entry-form-agreement-rules-website">AKC Rules, Regulations, Policies and Guidelines are available on the American Kennel Club website: <Link target="_blank" href="http://www.akc.org">www.akc.org</Link><LaunchIcon style={{fontSize: 18, color: 'blue'}} /></div>
          </Grid>
          <Grid item md={12}>
            <h1>Agreement</h1>
          </Grid>
          <Grid item md={10} p={2} >
            <div id="entry-form-agreement-text">{ agreementText }</div>
          </Grid>
          <Grid item md={12} p={2}>
            <Button 
              id="entry-form-agreement-agree-button"
              color="primary" 
              variant="contained"
              disabled={ agreeToAgreementDate !== '' }
              style={{textTransform: 'none'}}
              onClick={(e: any) => handleClickIAgree() }>
              I Agree
            </Button>
          </Grid>
        </>
      }
    </Grid>
  )
}
