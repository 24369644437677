import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Dialog, DialogActions, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { 
  setSessions,
  setTemplate,
  setMaxStudents,
  setActive,
  setActiveSessionIndex,
  setAnonymousOnly,
  setEventConfig,
  setSignupGranularity,
  setContactName,
  setContactEmail,
  setContactPronoun,
  setStartAvailableDate,
  setEndAvailableDate,
  setPayPalClientId, 
  setServiceFee,
  setServiceFeeBasis,
  setShowMinimalHeaderInfo,
  setShowFullness,
  setEmailOnCreateChangeDelete,
  setSingleReservationOnly,
  setIsSortable,
  setShowFields,
  setRegistrationMessage,
  setNotices } from '../../../redux/reducers/eventConfigSlice';
import { setAdministerEventId } from '../../../redux/reducers/registrantSlice';
import { setData } from '../../../redux/reducers/eventDataSlice';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import _ from 'lodash';
import './configView.css';
import AdminView from '../adminView/adminView';
import EntryFormView from '../entryFormView/entryFormView';
import SessionsView from '../sessionsView/sessionsView';
import ClassView from '../classView/classView';
import HelpDialog from '../../atoms/helpDialog/helpDialog';
import DeleteConfirmationDialog from '../../atoms/deleteConfirmationDialog/deleteConfirmationDialog';
import ConfigViewDates from '../../molecules/configViewDates/configViewDates';
import ConfigViewEntryEvents from '../../molecules/configViewEntryEvents/configViewEntryEvents';
import ConfigViewFiles from '../../molecules/configViewFiles/configViewFiles';
import ConfigViewRoster from '../../molecules/configViewRoster/configViewRoster';
import ConfigViewSessions from '../../molecules/configViewSessions/configViewSessions';
import { ConfigRecord } from '../../../models/ConfigRecord';
import { SessionConfig } from '../../../models/SessionConfig';
import { UserRecord } from '../../../models/UserRecord';
import { ReservationInfo } from '../../../models/ReservationInfo';
import DownloadInfo from '../downloadInfo/downloadInfo';

interface IConfigView {
  eventId: string,
  dataFetchTimeISO: string,
  saveReservation: (reservation: ReservationInfo) => void
  cancelReservation: (reservation: ReservationInfo) => void
  handleClassStatusChange: (newValue: any) => void
  handleAttendanceChange: (reservation: ReservationInfo) => void
  handleSessionSortChange: (sessions: SessionConfig[]) => void
  handleReservationSortChange: (reservations: ReservationInfo[]) => void
}

export default function ConfigView(props: IConfigView) {

  const { email, isAuth0Authenticated, jwtToken } = useAppSelector((state: any) => state.registrant);
  const { 
    _id, 
    active, 
    anonymousOnly, 
    contactEmail, 
    contactName, 
    contactPronoun, 
    notices,
    createdBy, 
    dates, 
    editors, 
    endAvailableDate,
    entryEvents, 
    files, 
    maxStudents, 
    payPalClientId,
    serviceFee,
    serviceFeeBasis,
    sessions, 
    signupGranularity,
    startAvailableDate,
    template, 
    title, 
    showMinimalHeaderInfo,
    showFullness,
    singleReservationOnly,
    showFields,
    sendRegistrationAck,
    isSortable,
    registrationMessage,
    url } = useAppSelector((state: any) => state.eventConfig);
  const { user, getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();

  const dispatch = useAppDispatch();

  const [ userRecord, setUserRecord ] = useState<null | UserRecord>(null);
  const [ events, setEvents ] = useState<ConfigRecord[]>([]);
  const [ currentEventIndex, setCurrentEventIndex ] = useState(-1);
  const [ viewingIndex, setViewingIndex ] = useState(-1);
  const [ currentEventIsDirty, setCurrentEventIsDirty ] = useState(false);
  const [ dialog, setDialog ] = useState({ open: false, title: '', action: null });
  const [ adminView, setAdminView ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);

  
  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const userRsp = await axios.get(`/api/protected/user/${email}`, {
          headers: { 
            Authorization: `Bearer ${jwtToken}`,
          }
        });

        const userEvents: ConfigRecord[] = [];
        if (!userRsp.data.error) {
          setUserRecord(userRsp.data);
          userRsp.data.events.forEach((userDataEventName: string) => {
            return axios.get(`api/config/${userDataEventName}`)
            .then((rsp: any) => {
              const data: ConfigRecord = rsp.data;
              let addedEvent = false;
              for (let i = 0; i < userEvents.length; i++) {
                if (data.title < userEvents[i].title) {
                  userEvents.splice(i, 0, { ...data, url: `https://${window.location.host}/${data._id}` });
                  addedEvent = true;
                  break;
                }
              };
              if (!addedEvent) {
                userEvents.push({ ...data, url: `https://${window.location.host}/${data._id}` });
              }
              if (userEvents.length === _.get(userRsp, 'data.events', []).length) {

                setEvents(userEvents);
              }
              setIsLoading(false);
            });
          });
        }
      } catch(err: any) {
        console.log('get constants akc-breeds err=' + JSON.stringify(err, null, 2));
        setIsLoading(false);
      }
    }
    if (jwtToken && email) {
      fetchData();
    }
  }, [email, getAccessTokenWithPopup, getAccessTokenSilently, isAuth0Authenticated, user, dispatch, jwtToken]);

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        let options: any = { params: { email }, headers:  { Authorization: `Bearer ${jwtToken}` }};
        const dataRsp = await axios.get(`/api/protected/data/${events[viewingIndex]._id}`, options);
        const data: any = _.get(dataRsp, 'data.data', []);
        const sessions: SessionConfig[] = [];
        _.get(events[viewingIndex], 'sessions', []).forEach((session: any, sessionIndex: number) => {
          const reservations: any[] = [];
          const defaultSortValues = session.runs.map(() => 100);

          const numberSlotsOffered = _.get(session, 'numberSlotsOffered', 0);
          for (let i=0; i < numberSlotsOffered; i++) {
            reservations.push({ id: i, status: 'Available', registrantName: '', registrantEmail: '', dogName: '', jumpHt: '', sortValues: defaultSortValues, edit: false })
          }
          data.forEach((result: any) => {
            result.reservations.forEach((reservation: any) => {
              if (reservation.sessionIndex === sessionIndex) {
                reservations[reservation.slotIndex].status = 'Reserved';
                reservations[reservation.slotIndex].dogName = reservation.dogName;
                reservations[reservation.slotIndex].jumpHt = reservation.jumpHt;
                reservations[reservation.slotIndex].sortValues = reservation.sortValues;
              }
            });
          });
          sessions.push({
            name: session.name,
            numberSlotsOffered: session.numberSlotsOffered,
            sortOrder: session.sortOrder,
            sortField: session.sortField,
            pricePerRun: session.pricePerRun,
            runs: session.runs,
            selected: sessionIndex === 0 ? true : false,
            reservations
          });
        });
        dispatch(setData(data));
        setIsLoading(false);
      } catch(err: any) {
        console.log('configView: get data err=' + JSON.stringify(err, null, 2));
        setIsLoading(false);
      }
    }
    fetchData();
  }, [viewingIndex, dispatch, jwtToken, events, email]);

  const onChangeEvent = () => {
    setCurrentEventIsDirty(true);
  }

  const saveConfigToDB = (newValues: any) => {
    /* istanbul ignore next */
    if (userRecord === null) {
      return;
    }

    const updatedObj: ConfigRecord = {
      _id: _id.replace('/', ''),
      url,
      active,
      title,
      createdBy,
      contactName,
      contactEmail,
      contactPronoun,
      notices,
      sessions,
      files,
      template,
      anonymousOnly,
      payPalClientId,
      serviceFee,
      serviceFeeBasis,
      maxStudents,
      signupGranularity,
      dates,
      entryEvents,
      editors,
      startAvailableDate,
      endAvailableDate,
      showMinimalHeaderInfo,
      showFullness: template === 'Show-N-Go' ? showFullness : undefined,
      sendRegistrationAck: template === 'Show-N-Go' ? sendRegistrationAck : undefined,
      singleReservationOnly: template === 'Show-N-Go' ? singleReservationOnly : undefined,
      isSortable: template === 'Show-N-Go' ? isSortable : undefined,
      showFields: template === 'Show-N-Go' ? showFields : undefined,
      registrationMessage: template === 'Show-N-Go' ? registrationMessage : undefined,
      ...newValues
    }
    const updatedEvents = _.cloneDeep(events);
    const eventIndex = _.findIndex(updatedEvents, (val: ConfigRecord) => val._id === _id);
    updatedEvents[eventIndex] = updatedObj;
    setEvents(updatedEvents);
    
    return axios.post(`/api/protected/config/${updatedObj._id}`, updatedObj, { params: { email }, headers: { Authorization: `Bearer ${jwtToken}`}})
    .then((rsp: any) => {
      setCurrentEventIsDirty(false);
      setCurrentEventIndex(-1);
      const updatedUserRecord = _.cloneDeep(userRecord);
      const userEventIndex = _.findIndex(updatedUserRecord.events, (val: string) => val === updatedObj._id);
      if (userEventIndex > -1) {
        return;
      }

      updatedUserRecord.events.push(updatedObj._id);
      
      return axios.post(`/api/protected/user/${userRecord._id}`, updatedUserRecord, { headers: { Authorization: `Bearer ${jwtToken}`}})
      .then((rsp: any) => {
        if (rsp.ok) {
          setUserRecord(updatedUserRecord);
        }
      });
    });
  }

  const getTemplates = (): string[] => {
    return [ 'Show-N-Go', 'Ad-Hoc', 'Files', 'Class', 'EntryForm', 'Volunteers', 'Auction', 'Search' ];
  }

  const getServiceFeeBasis = (): string[] => {
    return [ 'dog', 'owner' ];
  }

  const getSignupGranularity = (): string[] => {
    return [ 'slot', 'session', 'run' ];
  }

  const getContactPronouns = (): string[] => {
    return [ 'him', 'her', 'them' ];
  }
  const handleClickNewEvent = () => {
    dispatch(setAdministerEventId('/'));
    showDialog({action: 'New'});
  }

  const handleClickViewEvent = (eventIndex: number) => {
    const currentEvent: ConfigRecord = _.cloneDeep(events[eventIndex]);
    dispatch(setEventConfig({
      _id: currentEvent._id,
      url: `https://${window.location.host}/${currentEvent._id}`,
      title: currentEvent.title,
      contactName: currentEvent.contactName,
      contactEmail: currentEvent.contactEmail,
      contactPronoun: currentEvent.contactPronoun,
      notices: currentEvent.notices,
      startAvailableDate: currentEvent.startAvailableDate,
      endAvailableDate: currentEvent.endAvailableDate,
      template: currentEvent.template,
      sessions: currentEvent.sessions,
      files: currentEvent.files,
      dates: currentEvent.dates,
      entryEvents: currentEvent.entryEvents,
      active: currentEvent.active,
      createdBy: currentEvent.createdBy,
      editors: currentEvent.editors,
      maxStudents: currentEvent.maxStudents,
      anonymousOnly: currentEvent.anonymousOnly,
      payPalClientId: currentEvent.payPalClientId,
      serviceFee: currentEvent.serviceFee,
      serviceFeeBasis: currentEvent.serviceFeeBasis,
      signupGranularity: currentEvent.signupGranularity,
      updatedDate: currentEvent.updatedDate,
      activeSessionIndex: currentEvent.sessions.length > 0 ? 0 : -1,
      showMinimalHeaderInfo: currentEvent.showMinimalHeaderInfo,
      showFullness: currentEvent.showFullness,
      singleReservationOnly: currentEvent.singleReservationOnly,
      isSortable: currentEvent.isSortable,
      sendRegistrationAck: currentEvent.sendRegistrationAck,
      showFields: currentEvent.showFields,
      registrationMessage: currentEvent.registrationMessage
    }));
    setViewingIndex(eventIndex);
    setCurrentEventIndex(eventIndex);
    setAdminView(true);
    dispatch(setAdministerEventId(`/${currentEvent._id}`));
  }

  const handleClickEditEvent = (eventIndex: number) => {
    setViewingIndex(-1);
    const currentEvent: ConfigRecord = _.cloneDeep(events[eventIndex]);
    dispatch(setAdministerEventId(`/${currentEvent._id}`));
    dispatch(setEventConfig({
      _id: currentEvent._id,
      url: `https://${window.location.host}/${currentEvent._id}`,
      title: currentEvent.title,
      contactName: currentEvent.contactName,
      contactEmail: currentEvent.contactEmail,
      contactPronoun: currentEvent.contactPronoun,
      notices: currentEvent.notices,
      startAvailableDate: currentEvent.startAvailableDate,
      endAvailableDate: currentEvent.endAvailableDate,
      template: currentEvent.template,
      sessions: currentEvent.sessions,
      files: currentEvent.files,
      dates: currentEvent.dates,
      entryEvents: currentEvent.entryEvents,
      active: currentEvent.active,
      createdBy: currentEvent.createdBy,
      editors: currentEvent.editors,
      maxStudents: currentEvent.maxStudents,
      anonymousOnly: currentEvent.anonymousOnly,
      payPalClientId: currentEvent.payPalClientId,
      serviceFee: currentEvent.serviceFee,
      serviceFeeBasis: currentEvent.serviceFeeBasis,
      signupGranularity: currentEvent.signupGranularity,
      updatedDate: currentEvent.updatedDate,
      activeSessionIndex: sessions.length > 0 ? 0 : -1,
      showMinimalHeaderInfo: currentEvent.showMinimalHeaderInfo,
      showFullness: currentEvent.showFullness,
      sendRegistrationAck: currentEvent.sendRegistrationAck,
      singleReservationOnly: currentEvent.singleReservationOnly,
      isSortable: currentEvent.isSortable,
      showFields: currentEvent.showFields,
      registrationMessage: currentEvent.registrationMessage
    }));
    setCurrentEventIndex(eventIndex);
    setCurrentEventIsDirty(false);
  }

  const handleClickDeleteEvent = (eventIndex: number) => {
    dispatch(setAdministerEventId('/'));
    setViewingIndex(-1);
    /* istanbul ignore next */
    if (userRecord === null) {
      return;
    }
    const updatedEvents = _.cloneDeep(events);
    updatedEvents[eventIndex].deleteEventConfirmDialog = true;
    setEvents(updatedEvents);
  }

  const handleDeleteEventConfirmed = (action: string, name: string) => {
    /* istanbul ignore next */
    if (userRecord === null) {
      return;
    }

    if (action === 'confirm') {
      const updatedEvents = _.cloneDeep(events);
      const eventIndex = _.findIndex(events, (val: ConfigRecord) => val.title === name);
      updatedEvents[eventIndex].deleteEventConfirmDialog = false;
      setEvents(updatedEvents);

      dispatch(setActiveSessionIndex(-1));
      setCurrentEventIndex(-1);
      setCurrentEventIsDirty(false);

      const event = updatedEvents[eventIndex];
      delete event.deleteEventConfirmDialog;
      delete event.url;
      // delete event.sessions;
      const updatedUserRecord = _.cloneDeep(userRecord);

      const userEventIndex = _.findIndex(updatedUserRecord.events, (val: string) => val === events[eventIndex]._id);
  
      updatedUserRecord.events.splice(userEventIndex, 1);
      return axios.post(`/api/protected/user/${userRecord._id}`, updatedUserRecord, { headers: { Authorization: `Bearer ${jwtToken}`}})
      .then((rsp: any) => {
        if (rsp.ok) {
          setUserRecord(updatedUserRecord);
          axios.delete(`/api/protected/config/${event._id}`, { headers: { Authorization: `Bearer ${jwtToken}`}, params: { email }, data: event })
          .then((rsp: any) => {
            const updatedEvents = _.cloneDeep(events);
            const deletedIndex = _.findIndex(events, (val: ConfigRecord) => val._id === rsp.targetEventId);
            updatedEvents.splice(deletedIndex, 1);
            setEvents(updatedEvents);
          });
        }
      });
    }
  }

  const handleClickCopy = (eventIndex: number) => {
    setViewingIndex(-1);
    dispatch(setAdministerEventId('/'));
    const updatedEvents = _.cloneDeep(events);
    const newEvent = { ...events[eventIndex], title: events[eventIndex].title + ' - COPY', _id: events[eventIndex]._id + '-copy' }
    newEvent.active = false;
    updatedEvents.push(newEvent);
    setEvents(updatedEvents);
  }

  const handleClickActive = (eventIndex: number) => {
    setViewingIndex(-1);
    dispatch(setAdministerEventId('/'));
    const newValue = !active;
    dispatch(setActive(newValue));
    const updatedEvents = _.cloneDeep(events);
    updatedEvents[eventIndex].active = !updatedEvents[eventIndex].active;
    updatedEvents[eventIndex].deleteEventConfirmDialog = false;

    setEvents(updatedEvents);
    setCurrentEventIndex(eventIndex);
    // since we know only the active flag was changed, let's just call the API directly instead of handleClickSaveEvent->saveToDB
    return axios.post(`/api/protected/config/${updatedEvents[eventIndex]._id}`, updatedEvents[eventIndex], { params: { email }, headers: { Authorization: `Bearer ${jwtToken}`}})
  }

  const setStoreToCurrentEvent = (currentEvent: ConfigRecord) => {
    dispatch(setEventConfig({
      _id: currentEvent._id,
      url: `https://${window.location.host}/${currentEvent._id}`,
      title: currentEvent.title,
      contactName: currentEvent.contactName,
      contactEmail: currentEvent.contactEmail,
      contactPronoun: currentEvent.contactPronoun,
      notices: currentEvent.notices,
      createdBy: currentEvent.createdBy,
      editors: currentEvent.editors,
      startAvailableDate: currentEvent.startAvailableDate,
      endAvailableDate: currentEvent.endAvailableDate,
      sessions: currentEvent.sessions,
      files: currentEvent.files,
      dates: currentEvent.dates,
      active: currentEvent.active,
      template: currentEvent.template,
      signupGranularity: currentEvent.signupGranularity,
      anonymousOnly: currentEvent.anonymousOnly,
      payPalClientId: currentEvent.payPalClientId,
      serviceFee: currentEvent.serviceFee,
      serviceFeeBasis: currentEvent.serviceFeeBasis,
      showMinimalHeaderInfo: currentEvent.showMinimalHeaderInfo,
      showFullness: currentEvent.showFullness,
      sendRegistrationAck: currentEvent.sendRegistrationAck,
      singleReservationOnly: currentEvent.singleReservationOnly,
      isSortable: currentEvent.isSortable,
      showFields: currentEvent.showFields,
      registrationMessage: currentEvent.registrationMessage
    }));
  }

  const handleCloseDialogCreate = () => {
    const updatedEvents = _.cloneDeep(events);
    let _id = dialog.title.toLowerCase().replace(/[^A-Za-z0-9_]/g, '-');
    updatedEvents.push({ 
      _id, 
      url: `https://${window.location.host}/${_id}`,
      title: dialog.title,
      deleteEventConfirmDialog: false,
      createdBy: email,
      contactName: '',
      contactEmail: email,
      contactPronoun: '',
      notices: [],
      editors: [],
      sessions: [],
      reservations: [],
      files: [],
      dates: [],
      entryEvents: [],
      secretaryEvents: [],
      secretaryResults: [],
      active: false,
      signupGranularity: 'slot',
      template: getTemplates()[0],
      anonymousOnly: false,
      payPalClientId: '',
      serviceFee: 0,
      serviceFeeBasis: 'owner',
      startAvailableDate: '',
      endAvailableDate: '',
      showMinimalHeaderInfo: false,
      showFullness: true,
      sendRegistrationAck: false,
      singleReservationOnly: false,
      isSortable: true,
      showFields: undefined,
      registrationMessage: ''
    });
    setEvents(updatedEvents);
    setCurrentEventIndex(updatedEvents.length - 1);
    setCurrentEventIsDirty(true);
    setStoreToCurrentEvent(updatedEvents[updatedEvents.length - 1]);

    setDialog({ open: false, title: '', action: null });
  }

  const handleCloseDialogDismiss = () => {
    setDialog({ open: false, title: '', action: null });
  }

  const showDialog = (res: any) => {
    setDialog({ open: true, title: '', action: res.action });
  }

  const handleChangeDialogField = (e: any, fieldName: string) => {
    const updatedDialog: any = _.cloneDeep(dialog);
    updatedDialog[fieldName] = e.target.value;
    setDialog(updatedDialog);
  }

  const handleChangeField = (e: any, fieldName: string) => {
    if (fieldName === 'title') {
      const newTitle = e.target.value;
      const newId = newTitle.toLowerCase().replace(/[^A-Za-z0-9_]/g, '-');
      dispatch(setEventConfig({
        title: e.target.value,
        _id: newId,
        url: `https://${window.location.host}/${newId}`
      }));
    }
    if (fieldName === 'template') {
      dispatch(setTemplate(e.target.value));
      if (e.target.value === 'Class') {
        dispatch(setMaxStudents(0));
        dispatch(setSignupGranularity('session'));
      }
    }
    if (fieldName === 'contactName') {
      dispatch(setContactName(e.target.value));
    }
    if (fieldName === 'contactEmail') {
      dispatch(setContactEmail(e.target.value));
    }
    if (fieldName === 'contactPronoun') {
      dispatch(setContactPronoun(e.target.value));
    }
    if (fieldName === 'anonymousOnly') {
      dispatch(setAnonymousOnly(e.target.checked));
    }
    if (fieldName === 'maxStudents') {
      dispatch(setMaxStudents(parseInt(e.target.value)));
    }
    if (fieldName === 'signupGranularity') {
      dispatch(setSignupGranularity(e.target.value));
    }
    if (fieldName === 'payPalClientId') {
      dispatch(setPayPalClientId(e.target.value));
    }
    if (fieldName === 'serviceFee') {
      dispatch(setServiceFee(e.target.value ? e.target.value : 0));
    }
    if (fieldName === 'serviceFeeBasis') {
      dispatch(setServiceFeeBasis(e.target.value));
    }
    if (fieldName === 'showMinimalHeaderInfo') {
      dispatch(setShowMinimalHeaderInfo(e.target.checked));
    }
    if (fieldName === 'showFullness') {
      dispatch(setShowFullness(e.target.checked));
    }
    if (fieldName === 'sendRegistrationAck') {
      dispatch(setEmailOnCreateChangeDelete(e.target.checked));
    }
    if (fieldName === 'singleReservationOnly') {
      dispatch(setSingleReservationOnly(e.target.checked));
    }
    if (fieldName === 'isSortable') {
      dispatch(setIsSortable(e.target.checked));
    }
    if (fieldName === 'showFields') {
      dispatch(setShowFields(e.target.value.split(',')));
    }
    if (fieldName === 'registrationMessage') {
      dispatch(setRegistrationMessage(e.target.value));
    }
    setCurrentEventIsDirty(true);
  }

  const handleChangeDateField = (newValue: any, fieldName: string)  => {
    try {
      const newDateISO = new Date(newValue).toISOString();
      if (fieldName === 'startAvailableDate') {
        dispatch(setStartAvailableDate(newDateISO));
      }
      if (fieldName === 'endAvailableDate') {
        dispatch(setEndAvailableDate(newDateISO));
      }
      setCurrentEventIsDirty(true);
    }
    catch {}
  }

  const handleClickAddNotice = () => {
    const updatedNotices = _.cloneDeep(notices);
    updatedNotices.push('');
    dispatch(setNotices(updatedNotices));
    setCurrentEventIsDirty(true);
  }

  const handleClickDeleteNotice = (i: number) => {
    const updatedNotices = _.cloneDeep(notices);
    updatedNotices.splice(i, 1);
    dispatch(setNotices(updatedNotices));
    setCurrentEventIsDirty(true);
  }

  const handleChangeNotice = (e: any, i: number) => {
    const updatedNotices = _.cloneDeep(notices);
    updatedNotices[i] = e.target.value;
    dispatch(setNotices(updatedNotices));
    setCurrentEventIsDirty(true);
  }

  const handleClickSaveEvent = () => {
    if ([ 'Class', 'Ad-Hoc' ].includes(template) && sessions.length === 0) {
      const updatedSessions: SessionConfig[] = [
        {
          numberSlotsOffered: maxStudents ? maxStudents : 0,
          pricePerRun: 60,
          name: 'Class',
          sortOrder: [
            'asc'
          ],
          sortField: 'jumpHt',
          runs: [
            'Std'
          ],
          reservations: []
        }
      ]
      dispatch(setSessions(updatedSessions));
      saveConfigToDB({ sessions: updatedSessions });
    } else {
      saveConfigToDB({});
    }
  }

  const formatDate = (isoDate: string) => {
    const obj = new Date(isoDate);
    return obj.toLocaleString('en-US', { timeZone: 'America/New_York' });
  }

  return (
    <>
      { isLoading ? 
        <h1>Loading...</h1>
        :
        <>
          <div className="config-view-content-wrapper">
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item className='noprint' md={12} p={1}>
                <Button 
                  color="primary" 
                  variant="contained"
                  className="config-view-new-event"
                  style={{textTransform: 'none'}}
                  disabled={userRecord === null}
                  onClick={() => handleClickNewEvent()}>
                  New Event
                </Button>
              </Grid>
            </Grid>
            { userRecord && events.map((ev: ConfigRecord, eventIndex: number) => (
              <Grid container direction="row" justifyContent="center" alignItems="center" key={eventIndex} className="noprint config-view-card">
                <Grid item md={4} xs={12} p={1}>
                  <div>
                    <Button
                        key={`view-event-button-${eventIndex}`}
                        className="config-view-event-action-button"
                        id="view-event-button"
                        color="primary" 
                        size="small"
                        variant="contained"
                        style={{textTransform: 'none'}}
                        onClick={() => handleClickViewEvent(eventIndex)}>
                        View
                      </Button>
                      <Button
                        key={`edit-event-button-${eventIndex}`}
                        className="config-view-event-action-button"
                        id="edit-event-button"
                        color="primary" 
                        size="small"
                        variant="contained"
                        style={{textTransform: 'none'}}
                        onClick={() => handleClickEditEvent(eventIndex)}>
                        Edit
                      </Button>
                      <Button
                        key={`delete-event-button-${eventIndex}`}
                        className="config-view-event-action-button"
                        id="delete-event-button"
                        color="primary" 
                        size="small"
                        variant="contained"
                        style={{textTransform: 'none'}}
                        onClick={() => handleClickDeleteEvent(eventIndex)}>
                        Delete
                      </Button>
                    <DeleteConfirmationDialog 
                      targetType={"Delete Event"}
                      name={events[eventIndex].title}
                      open={events[eventIndex].deleteEventConfirmDialog || false} 
                      closeDialog={handleDeleteEventConfirmed} />
                    <Button
                      key={`copy-event-button-${eventIndex}`}
                      className="config-view-event-action-button"
                      id="copy-event-button"
                      color="primary" 
                      size="small"
                      variant="contained"
                      style={{textTransform: 'none'}}
                      onClick={() => handleClickCopy(eventIndex)}>
                      Copy
                    </Button>
                    <Button
                      key={`archive-event-button-${eventIndex}`}
                      className="config-view-event-action-button"
                      id="active-archive-button"
                      color="primary" 
                      size="small"
                      variant="contained"
                      style={{textTransform: 'none'}}
                      onClick={() => handleClickActive(eventIndex)}>
                      {ev.active ? 'Archive' : 'Make Active' }
                    </Button>
                  </div>
                </Grid>
                <Grid item md={8} xs={12} p={1}>
                  <span className="config-view-event-details" style={{ color: ev.active ? 'black' : '#cccccc' }}>{ ev.title } - {ev.updatedDate ? 'Last saved on ' + formatDate(ev.updatedDate) : 'not saved'}</span>
                </Grid>
              </Grid>
            ))}
            { viewingIndex === -1 && currentEventIndex > -1 ?
              <React.Fragment key={'event-fragment'}>
                <div className="config-view-edit-container"></div>
                <Grid container justifyContent="center" alignItems="center" alignContent="center" spacing={1}>
                  <Grid item key={'event-id'} md={6}>
                    <div>
                      <TextField 
                        id={'current-id'}
                        variant="outlined"
                        size="small"
                        disabled={true}
                        className="config-view-event-field"
                        value={_id}
                        label="id" />
                    </div>
                  </Grid>
                  <Grid item key={'event-url'} md={6}>
                    <div>
                      <TextField 
                        id={'current-url'}
                        variant="outlined"
                        size="small"
                        disabled={true}
                        className="config-view-event-field"
                        value={url}
                        label="url" />
                    </div>
                  </Grid>
                  <Grid item key={'event-title'} md={6}>
                    <div>
                      <TextField 
                        id={'current-title'}
                        variant="outlined"
                        size="small"
                        disabled={true}
                        className="config-view-event-field"
                        value={title}
                        onChange={(e: any) => handleChangeField(e, 'title')}
                        label="id" />
                        <HelpDialog subject={'title'} />
                    </div>
                  </Grid>
                  <Grid item key={'event-field-template'} md={6}>
                    <FormControl sx={{ m: 1, minWidth: 180 }}>
                      <div>
                        <InputLabel id="event-type-select-label">Event Type</InputLabel>
                        <Select
                          id="event-template-select"
                          labelId="event-template-select-label"
                          label="Event Type"
                          style={{width: 200}}
                          value={template}
                          onChange={(e: any) => handleChangeField(e, 'template')}>
                          { getTemplates().map((val: string, index: number) => (
                            <MenuItem key={'event-template-select-' + index} value={val}>{val}</MenuItem>
                          ))}
                        </Select>
                        <HelpDialog subject="template" />
                      </div>
                    </FormControl>
                  </Grid>

                  <Grid item key={'event-field-contact-name'} md={4}>
                    <FormControl className="config-view-event-contact-name">
                      <div>
                        <TextField 
                          id={'current-contact-name'}
                          variant="outlined"
                          size="small"
                          className="config-view-event-contact-name"
                          value={contactName}
                          onChange={(e: any) => handleChangeField(e, 'contactName')}
                          label="Contact Name(s)" />
                          <HelpDialog subject={'contactName'} />
                      </div>
                    </FormControl>
                  </Grid>

                  <Grid item key={'event-field-contact-email'} md={4}>
                    <FormControl fullWidth>
                      <div>
                        <TextField 
                          id={'current-contact-email'}
                          variant="outlined"
                          size="small"
                          className="config-view-contact-email"
                          value={contactEmail}
                          onChange={(e: any) => handleChangeField(e, 'contactEmail')}
                          label="Contact Email(s)" />
                          <HelpDialog subject={'contactEmail'} />
                      </div>
                    </FormControl>
                  </Grid>

                  <Grid item key={'event-field-conact-pronoun'} md={4}>
                    <FormControl className="config-view-event-contact-pronoun" sx={{ m: 1, minWidth: 180 }}>
                      <div>
                        <InputLabel id="event-contact-pronoun-select-label">Contact Pronoun</InputLabel>
                        <Select
                          id="event-contact-pronoun-select"
                          labelId="event-contact-pronoun-select-label"
                          label="Contact Pronoun"
                          style={{width: 200}}
                          value={contactPronoun}
                          onChange={(e: any) => handleChangeField(e, 'contactPronoun')}>
                          { getContactPronouns().map((val: string, index: number) => (
                            <MenuItem key={'event-contact-pronoun-select-' + index} value={val}>{val}</MenuItem>
                          ))}
                        </Select>
                        <HelpDialog subject="contactPronoun" />
                      </div>
                    </FormControl>
                  </Grid>
                  <Grid item key={'event-field-show-minimal-header-info'} md={4}>
                    <FormControl fullWidth>
                      <div>
                        <Checkbox 
                          checked={showMinimalHeaderInfo}
                          id={'event-show-minimal-header-info'}
                          onChange={(e: any) => handleChangeField(e, 'showMinimalHeaderInfo')}
                          color="primary" />
                        <span>Show Minimal Header Info (e.g. exclude Login, Products)</span>
                      </div>
                    </FormControl>
                  </Grid>
                  { template === 'Show-N-Go' ?
                    <>
                      <Grid item key={'event-field-show-fullness'} md={4}>
                        <FormControl fullWidth>
                          <div>
                            <Checkbox 
                              checked={showFullness}
                              id={'event-show-fullness'}
                              onChange={(e: any) => handleChangeField(e, 'showFullness')}
                              color="primary" />
                            <span>Show Fullness of event to client</span>
                          </div>
                        </FormControl>
                      </Grid>
                      <Grid item key={'event-email-on-create-change-delete'} md={4}>
                        <FormControl fullWidth>
                          <div>
                            <Checkbox 
                              checked={sendRegistrationAck}
                              id={'event-email-on-create-change-delete'}
                              onChange={(e: any) => handleChangeField(e, 'sendRegistrationAck')}
                              color="primary" />
                            <span>Email admin and client on reservation modification</span>
                          </div>
                        </FormControl>
                      </Grid>
                      <Grid item key={'event-single-reservation-only'} md={4}>
                        <FormControl fullWidth>
                          <div>
                            <Checkbox 
                              checked={singleReservationOnly}
                              id={'event-single-reservation-only'}
                              onChange={(e: any) => handleChangeField(e, 'singleReservationOnly')}
                              color="primary" />
                            <span>Only allow on reservation per registrant</span>
                          </div>
                        </FormControl>
                      </Grid>
                      <Grid item key={'event-is-sortable'} md={4}>
                        <FormControl fullWidth>
                          <div>
                            <Checkbox 
                              checked={isSortable}
                              id={'event-is-sortable'}
                              onChange={(e: any) => handleChangeField(e, 'isSortable')}
                              color="primary" />
                            <span>Is Sortable (for Show-N-Go where the admin will want to sort the running order)</span>
                          </div>
                        </FormControl>
                      </Grid>
                      <Grid item key={'event-show-fields'} md={6}>
                        <FormControl fullWidth>
                          <div>
                            <TextField 
                              id={'event-show-fields'}
                              variant="outlined"
                              size="small"
                              value={showFields}
                              onChange={(e: any) => handleChangeField(e, 'showFields')}
                              label="Reservation fields" />
                          </div>
                        </FormControl>
                      </Grid>
                      <Grid item key={'event-registration-message'} md={6}>
                        <FormControl fullWidth>
                          <div>
                            <TextField 
                              id={'event-registration-message'}
                              variant="outlined"
                              size="small"
                              value={registrationMessage}
                              onChange={(e: any) => handleChangeField(e, 'registrationMessage')}
                              label="Message to display during registration" />
                          </div>
                        </FormControl>
                      </Grid>
                    </>
                    : null
                  }
                  <Grid item key={'event-field-notice-header'} md={12}>
                    <h2>Notices</h2>
                  </Grid>
                  <Grid item key={'event-field-notice-add'} md={12}>
                    <Button
                      id="configView-add-notice-button"
                      color="primary" 
                      variant="contained"
                      style={{textTransform: 'none'}}
                      onClick={handleClickAddNotice}>
                      Add Notice
                    </Button> 
                  </Grid>
                  { notices.map((notice: string, noticeIndex: number) => (
                    <Grid item key={`event-field-notice-${noticeIndex}-wrapper`} md={8}>
                      <FormControl key={`event-field-notice-${noticeIndex}`} fullWidth>
                        <div>
                          <TextField 
                            id={`current-notice-${noticeIndex}`}
                            variant="outlined"
                            size="small"
                            className="config-view-notice"
                            value={notice}
                            onChange={(e: any) => handleChangeNotice(e, noticeIndex)}
                            label={ `Notice ${noticeIndex + 1}`} />
                          <Tooltip title="Delete">
                            <IconButton 
                              key={`current-notice-delete-button-${noticeIndex}`}
                              color="primary" 
                              aria-label="delete notice"
                              component="span"
                              onClick={() => handleClickDeleteNotice(noticeIndex)}>
                              <DeleteIcon style={{ fontSize: 18 }} />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </FormControl>
                    </Grid>
                  ))}

                  { ['Show-N-Go'].includes(template) ?
                    <Grid item key={'event-field-signup-granularity'} md={12}>
                      <FormControl className="config-view-event-signup-granularity" sx={{ m: 1, minWidth: 180 }}>
                        <div>
                          <InputLabel id="event-signup-granularity-select-label">Signup Granularity</InputLabel>
                          <Select
                            id="event-signup-granularity-select"
                            labelId="event-signup-granularity-select-label"
                            label="Signup Granularity"
                            style={{width: 200}}
                            value={signupGranularity}
                            onChange={(e: any) => handleChangeField(e, 'signupGranularity')}>
                            { getSignupGranularity().map((val: string, index: number) => (
                              <MenuItem key={'event-signup-granularity-select-' + index} value={val}>{val}</MenuItem>
                            ))}
                          </Select>
                          <HelpDialog subject="signupGranularity" />
                        </div>
                      </FormControl>
                    </Grid>
                    : null
                  }
                  
                  { ['EntryForm'].includes(template) ?
                    <>
                      <Grid item key={'event-field-signup-paypal-clientid'} md={12}>
                        <FormControl className="config-view-event-paypal-clientid">
                          <div>
                            <TextField 
                              id={'current-paypal-clientid'}
                              variant="outlined"
                              size="small"
                              className="config-view-event-paypal-clientid"
                              value={payPalClientId}
                              onChange={(e: any) => handleChangeField(e, 'payPalClientId')}
                              label="PayPal Client ID" />
                              <HelpDialog subject={'payPalClientId'} />
                          </div>
                        </FormControl>
                      </Grid>
                      <Grid item key={'event-field-signup-service-fee'} md={12}>
                        <FormControl className="config-view-event-service-fee">
                          <div>
                            <TextField 
                              id={'current-service-fee'}
                              variant="outlined"
                              size="small"
                              className="config-view-event-service-fee"
                              value={serviceFee}
                              onChange={(e: any) => handleChangeField(e, 'serviceFee')}
                              label="Service Fee" />
                              <HelpDialog subject={'serviceFee'} />
                          </div>
                        </FormControl>
                        { serviceFee > 0 ?
                          <>
                          <FormControl className="config-view-event-service-fee-basis">
                              <div>
                                <InputLabel id="event-type-select-label">Service Fee Basis</InputLabel>
                                <Select
                                  id="event-template-select"
                                  labelId="event-service-fee-basis-select-label"
                                  label="Service Fee Basis"
                                  style={{width: 520}}
                                  value={serviceFeeBasis}
                                  onChange={(e: any) => handleChangeField(e, 'serviceFeeBasis')}>
                                  { getServiceFeeBasis().map((val: string, index: number) => (
                                    <MenuItem key={'event-service-fee-basis-select-' + index} value={val}>{val}</MenuItem>
                                  ))}
                                </Select>
                                <HelpDialog subject="serviceFeeBasis" />
                              </div>
                            </FormControl>
                          </>
                          : <></>
                        }
                      </Grid>
                      <Grid item key={'event-field-signup-available-dates'} md={12}>
                        <FormControl className="config-view-event-start-available-date">
                          <div>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DateTimePicker
                                label="Start Available"
                                value={startAvailableDate}
                                onChange={(newValue: any) => handleChangeDateField(newValue, 'startAvailableDate')}
                                renderInput={(params: any) => <TextField { ...params } id="config-view-event-start-available-date" sx={{ m: 1 }} />}
                              />
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DateTimePicker
                                label="End Available"
                                value={endAvailableDate}
                                onChange={(newValue: any) => handleChangeDateField(newValue, 'endAvailableDate')}
                                renderInput={(params: any) => <TextField { ...params } id="config-view-event-end-available-date" sx={{ m: 1 }} />}
                              />
                            </LocalizationProvider>
                            <HelpDialog subject={'entryFormAvailableDates'} />
                          </div>
                        </FormControl>
                      </Grid>
                      <Grid item key={'event-field-events'} md={12}>
                        <ConfigViewEntryEvents onChange={onChangeEvent}/>
                      </Grid>
                    </>
                    : null
                  }

                  { ['Files'].includes(template) ?
                    <Grid item key={'event-field-anonymous-only'} md={12}>
                      <FormControl className="config-view-event-anonymouns-only" sx={{ m: 1, minWidth: 180 }}>
                        <div>
                          <Checkbox 
                            checked={anonymousOnly}
                            name="anonymousOnly"
                            id="anonymousOnly"
                            onChange={(e: any) => handleChangeField(e, 'anonymousOnly') }
                            color="primary" />
                          <span>Anonymous downloads (no name/email input)</span>
                          <HelpDialog subject="anonymousOnly" />
                        </div>
                      </FormControl>
                    </Grid>
                    : null
                  }
                  <ConfigViewFiles onChange={onChangeEvent}/>

                  { ['Show-N-Go'].includes(template) ?
                    <>
                      <ConfigViewSessions onChange={onChangeEvent}/>                
                    </>
                    : null
                  }
                  { ['Class', 'Ad-Hoc'].includes(template) ?
                    <>
                      <Grid key={'event-field-max-students'} item md={12}>
                        <TextField 
                          id={'current-event-max-students'}
                          variant="outlined"
                          size="small"
                          className="config-view-event-max-students"
                          value={maxStudents}
                          onChange={(e: any) => handleChangeField(e, 'maxStudents')}
                          label="Maximum number of students" />
                        <ConfigViewDates onChange={onChangeEvent}/>
                        <ConfigViewRoster onChange={onChangeEvent}/>
                      </Grid>
                    </>
                    : null
                  }
                </Grid>
                <div className="config-view-button-wrapper">
                  <Button
                    id="configView-save-button"
                    color="primary" 
                    variant="contained"
                    style={{textTransform: 'none'}}
                    disabled={!currentEventIsDirty}
                    onClick={handleClickSaveEvent}>
                    Save Event
                  </Button>
                </div>
              </React.Fragment>
              : null
            }
            { viewingIndex > -1 ? 
              <Grid container alignItems="center" alignContent="center">
                <Grid item md={12} p={1}>
                  <h1>{ events[viewingIndex].title }</h1> 
                </Grid>
                { ['Show-N-Go'].includes(events[viewingIndex].template)  ?
                  <Grid item md={12} p={1}>
                    <div className="noprint">
                      <Checkbox 
                        checked={adminView}
                        name="adminView"
                        id="adminView"
                        aria-label="admin view"
                        onChange={() => setAdminView(!adminView) }
                        color="primary" />
                      <span>Admin View</span>
                    </div>
                  </Grid>
                  : null
                }
                { ['Show-N-Go'].includes(events[viewingIndex].template) && sessions.length > 0 ?
                  <SessionsView 
                    eventId={events[viewingIndex]._id} 
                    saveReservation={props.saveReservation}
                    cancelReservation={props.cancelReservation}/>
                  : null
                }

                {  ['Files'].includes(events[viewingIndex].template) && files.length > 0 ?
                  <DownloadInfo eventId={events[viewingIndex]._id} files={files} dataFetchTimeISO={props.dataFetchTimeISO}></DownloadInfo>
                  : null
                }

                { ['Class', 'Ad-Hoc'].includes(events[viewingIndex].template) ?
                  <ClassView 
                    onStudentAttendanceStatusChange={props.handleAttendanceChange}
                    onClassStatusChange={props.handleClassStatusChange}
                  />
                  : null
                }
                
                { ['EntryForm'].includes(events[viewingIndex].template) ?
                  <EntryFormView />
                  : null
                }

                { adminView ?
                  <AdminView 
                    handleSessionSortChange={props.handleSessionSortChange}
                    handleReservationSortChange={props.handleReservationSortChange} />
                  : null
                }
              </Grid>
              : null
            }
            <Dialog 
              id="config-view-create-event-dialog"
              open={dialog.open}
              onClose={() => handleCloseDialogDismiss()}
              aria-labelledby="config-view-create-event-dialog-title"
              aria-describedby="config-view-create-event-dialog-description">
              <DialogTitle id="config-view-create-event-dialog-title">{dialog.action}</DialogTitle>
              <TextField 
                id={'new-event-title'}
                className="config-view-event-title-input"
                variant="outlined"
                size="small"
                value={dialog.title}
                onChange={(e: any) => handleChangeDialogField(e, 'title')}
                label="event title" />
              <DialogActions>
                <Button 
                  id={'new-event-create'}
                  onClick={() => handleCloseDialogCreate()}
                  disabled={dialog.title === ''}
                  color="primary"
                  autoFocus>
                  Create
                </Button>
                <Button 
                  id="new-event-dismiss-dialog"
                  onClick={() => handleCloseDialogDismiss()}
                  color="primary">
                  Dismiss
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </>
      }
    </>
  )
}
