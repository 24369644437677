import React, { useState } from 'react';
import { Button, Grid } from '@mui/material';
import { ShoppingCart as ShoppingCartIcon } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setEdittingIndex, setEntries, setStepIndex, setSumEntryFee, setTotalServiceFee } from '../../../redux/reducers/entryFormsSlice';
import _ from 'lodash';
import './entryFormSummary.css';
import EntryFormData from '../entryFormData/entryFormData';
import { EntryEventConfig } from '../../../models/EntryEventConfig';
import { EntryFormEventInfo } from '../../../models/EntryFormEventInfo';
import { EntryFormRecordData } from '../../../models/EntryFormRecordData';
import { UserRecordDog } from '../../../models/UserRecordDog';

export default function EntryFormSummary() {

  const { email, firstName, lastName } = useAppSelector((state: any) => state.registrant);
  const { ownerAddress, dogs } = useAppSelector((state: any) => state.userProfile);
  const { entryEvents, serviceFee, serviceFeeBasis } = useAppSelector((state: any) => state.eventConfig);
  const { edittingIndex, entries  } = useAppSelector((state: any) => state.entryForms);
  const [ dogIndicesAdded, setDogIndicesAdded ] = useState<number[]>([]);
  const dispatch = useAppDispatch();

  const setBlankEntry = (data: any[]): EntryFormRecordData => {
    const defaultBreed = dogs.length > 0 ? dogs[dogs.length -1].breed : '';
    const defaultSex = dogs.length > 0 ? dogs[dogs.length -1].sex : '';
    return {
      createdDate: new Date().toISOString(),
      lastSavedDate: '',
      uuid: uuidv4(),
      eventEntries: _.cloneDeep(data),
      entryFee: 0,
      breed: defaultBreed,
      variety: '',
      sex: defaultSex,
      jrHandlerName: '',
      jrHandlerNumber: '',
      callName: '',
      akcName: '',
      akcRegistrationNum: '',
      dateOfBirth: '',
      countryOfBirth: 'USA',
      breeder: '',
      sire: '',
      dam: '',
      owners: firstName && lastName ? `${firstName.trim()} ${lastName.trim()}` : '',
      ownerAddress: {
        first: _.get(ownerAddress, 'first', '') !== '' ? ownerAddress.first : firstName.trim(),
        last: _.get(ownerAddress, 'last', '') !== '' ? ownerAddress.last : lastName.trim(),
        street: _.get(ownerAddress, 'street', '') !== '' ? ownerAddress.street : '',
        city: _.get(ownerAddress, 'city', '') !== '' ? ownerAddress.city : '',
        state: _.get(ownerAddress, 'state', '') !== '' ? ownerAddress.state : '',
        zip: _.get(ownerAddress, 'zip', '') !== '' ? ownerAddress.zip : '',
        phone: _.get(ownerAddress, 'phone', '') !== '' ? ownerAddress.phone : ''
      },
      handler: '',
      agreeToAgreementDate: '',
      email: email ? email : ownerAddress.email ? ownerAddress.email : '',
      payPalTransactions: []
    }
  }

  const setFromExistingDog = (data: any[], dogIndex: number): EntryFormRecordData => {
    const newEntry =  {
      createdDate: new Date().toISOString(),
      lastSavedDate: '',
      uuid: uuidv4(),
      eventEntries: _.cloneDeep(data),
      entryFee: 0,
      breed: dogs[dogIndex].breed,
      variety: dogs[dogIndex].variety,
      sex: dogs[dogIndex].sex,
      jrHandlerName: dogs[dogIndex].jrHandlerName,
      jrHandlerNumber: dogs[dogIndex].jrHandlerNumber,
      callName: dogs[dogIndex].callName,
      akcName: dogs[dogIndex].akcName,
      akcRegistrationNum: dogs[dogIndex].akcRegistrationNum,
      dateOfBirth: dogs[dogIndex].dateOfBirth,
      countryOfBirth: dogs[dogIndex].countryOfBirth,
      breeder: dogs[dogIndex].breeder,
      sire: dogs[dogIndex].sire,
      dam: dogs[dogIndex].dam,
      owners: dogs[dogIndex].owners,
      ownerAddress: {
        first: ownerAddress.first,
        last: ownerAddress.last,
        street: ownerAddress.street,
        city: ownerAddress.city,
        state: ownerAddress.state,
        zip: ownerAddress.zip,
        phone: ownerAddress.phone,
      },
      handler: dogs[dogIndex].handler,
      email: ownerAddress.email,
      agreeToAgreementDate: '',
      payPalTransactions: []
    }
    const indicesAdded: number[] = dogIndicesAdded;
    indicesAdded.push(dogIndex);
    setDogIndicesAdded(indicesAdded);
    return newEntry;
  }
  const handleClickAddEntry = (e: any, dogIndex: number = -1) => {
    const updatedEntries: EntryFormRecordData[] = _.cloneDeep(entries);
    const data: any[] = [];
    entryEvents.forEach((entryEvent: EntryEventConfig) => {
      const entryClasses: { name: string }[] = [];
      entryEvent.classesOffered.forEach(entryEventClassOffered => {
        entryClasses.push({ name: entryEventClassOffered });
      });
      data.push({
        eventId: `${entryEvent.name} - ${entryEvent.eventNum}`,
        eventNum: entryEvent.eventNum,
        name: entryEvent.name,
        classes: entryClasses
      });
    });
    const newEntry: EntryFormRecordData = dogIndex > -1 ? setFromExistingDog(data, dogIndex) : setBlankEntry(data);

    newEntry.eventEntries.forEach((eventEntry: EntryFormEventInfo) => {
      eventEntry.classes.forEach(eventEntryClass => {
        eventEntryClass.isEntered = false;
      });
    });
    updatedEntries.push(newEntry);
    dispatch(setEntries(updatedEntries));
    dispatch(setEdittingIndex(updatedEntries.length - 1));
    setEdittingIndex(updatedEntries.length - 1);
  }

  const handleClickEditEntry = (entryIndex: number) => {
    dispatch(setEdittingIndex(entryIndex));
  }
  
  const handleClickDeleteEntry = (entryIndex: number) => {
    const updatedEntries: EntryFormRecordData[] = _.cloneDeep(entries);
    if (dogIndicesAdded.length > 0) {
      const dogIndex = _.findIndex(dogs, (dog: UserRecordDog) => dog.callName === updatedEntries[entryIndex].callName);
      const indices = dogIndicesAdded;
      const i = _.findIndex(indices, val => val === dogIndex);
      indices.splice(i, 1); 
      setDogIndicesAdded(indices);
    }
    updatedEntries.splice(entryIndex, 1);
    dispatch(setEntries(updatedEntries));
    dispatch(setEdittingIndex(-1));
  }

  const getShortName = (fullName: string) => {
    let shortName = fullName.replace('Rally ', '');
    shortName = shortName.replace('Novice ', 'Nov ');
    shortName = shortName.replace('Intermediate', 'Int');
    shortName = shortName.replace('Advanced ', 'Adv ');
    shortName = shortName.replace('Excellent ', 'Ex ');
    shortName = shortName.replace('Master', 'Mas');
    shortName = shortName.replace('Utility ', 'Util ');
    shortName = shortName.replace('Beginner ', 'Beg ');
    shortName = shortName.replace('Obed ', '');
    shortName = shortName.replace('Novice', 'Nov');
    shortName = shortName.replace('Preferred ', 'Pref ');
    shortName = shortName.replace('Graduate ', 'Grad ');
    shortName = shortName.replace('Versatility', 'Ver');
    return shortName;
  }

  const getAbbreviatedClasses = (eventEntry: EntryFormEventInfo) => {
    const retValue: string[] = [];
    let isFirstClassThisEvent = true;
    eventEntry.classes.forEach((eventEntryClass: any) => {
      if (eventEntryClass.isEntered) {
        if (isFirstClassThisEvent) {
          retValue.push(getShortName(eventEntryClass.name));
          isFirstClassThisEvent = false;
        } else {
          retValue[retValue.length-1] = retValue[retValue.length-1] + ',' + getShortName(eventEntryClass.name);
        }
      }
    });
    return retValue.toString();
  }

  const handleClickDone = () => {
    let updatedSumEntryFee = 0;
    entries.forEach((entry: EntryFormRecordData) => {
      updatedSumEntryFee += entry.entryFee;
    });
    let updatedTotalServiceFee = 0;
    if (serviceFeeBasis === 'dog') {
      updatedTotalServiceFee = serviceFee * entries.length;
    }
    if (serviceFeeBasis === 'owner') {
      updatedTotalServiceFee = serviceFee;
    }
    dispatch(setSumEntryFee(updatedSumEntryFee));
    dispatch(setTotalServiceFee(updatedTotalServiceFee));
    dispatch(setStepIndex(1));
  }

  return (
    <>
      <Grid key={'entry-form-data-header'} item md={12}>
        <h2><ShoppingCartIcon />Cart</h2>
      </Grid>
      { entries.map((entry: EntryFormRecordData, entryIndex: number) => (
        <Grid container key={'entry-form-summary-entry-' + entryIndex} spacing={2}>
          { entry.lastSavedDate !== '' ?
            <Grid item md={12} p={2}>
              <div className="entry-form-summary-entry">
                <span className='entry-form-summary-call-name' id={'entry-form-summary-call-name-' + entryIndex}>{entry.callName } -</span>
                { entry.eventEntries.map((eventEntry: EntryFormEventInfo, eventEntryIndex: number) => 
                  <React.Fragment key={`entry-form-summary-event-${entryIndex}-${eventEntryIndex}`}>
                    { getAbbreviatedClasses(eventEntry) !== '' ?
                      <>
                        <span className='entry-form-summary-event-name'>{eventEntry.name}</span>
                        <span id={`entry-form-summary-event-classes-${eventEntryIndex}`}> - {getAbbreviatedClasses(eventEntry)}; </span>
                      </>
                      : null
                    }
                  </React.Fragment>
                )}
                <span>Fees: ${entry.entryFee.toFixed(2)}</span>
                <Button 
                  id={`entry-form-summary-${entryIndex}-edit-button`}
                  className="entry-form-summary-button"
                  key={`entry-form-summary-${entryIndex}-edit-button`}
                  color="primary" 
                  variant="contained"
                  style={{textTransform: 'none', marginLeft: '10px'}}
                  disabled={ edittingIndex > -1 }
                  onClick={() => handleClickEditEntry(entryIndex)}>
                  Edit
                </Button>
                <Button 
                  id={`entry-form-summary-${entryIndex}-delete-button`}
                  key={`entry-form-summary-${entryIndex}-delete-button`}
                  className="entry-form-summary-button"
                  color="primary" 
                  variant="contained"
                  style={{textTransform: 'none', marginLeft: '10px'}}
                  onClick={() => handleClickDeleteEntry(entryIndex)}>
                  Delete
                </Button>
                { entryIndex === edittingIndex ?
                  <span style={{marginLeft: '10px'}}>Currrently editting</span>
                  : null
                }
              </div>
            </Grid>
            : null
          }
        </Grid>
      ))}
      { entries.length === 0 ?
        <div className="entry-form-summary-cart-status-msg">Your cart is empty. Click 'Add New entry'.</div>
        : 
          <>
            { edittingIndex > -1 ?
              <EntryFormData />
              : <div className="entry-form-summary-cart-status-msg">No entry selected. Click 'Add New entry' or 'Edit' for an existing entry</div>
            }
          </>
      }
      { edittingIndex === -1 ? 
        <>
          { dogs.length > 0 ?
            <Grid container key={`user-record-dog-header`} spacing={2} justifyContent="center" alignItems="center">
              <Grid item md={2}>
                <div>
                  <h3>Dogs from Profile</h3>
                </div>
              </Grid>
            </Grid>
            : null
          }
          { dogs.map((dog: UserRecordDog, dogIndex: number) =>
            <Grid container key={`user-record-dog-${dogIndex}`} spacing={2} justifyContent="center" alignItems="center">
              <Grid item md={1} p={2}>
                <span>{ dog.callName }:</span>
              </Grid>
              <Grid item md={2} p={2}>
                <Button 
                  id={`entry-form-summary-existing-dog-${dogIndex}`}
                  color="primary" 
                  variant="contained"
                  className="entry-form-summary-button"
                  style={{textTransform: 'none'}}
                  disabled={ dogIndicesAdded.includes(dogIndex) }
                  onClick={(e: any) => handleClickAddEntry(e, dogIndex)}>
                  Add Entry for { dog.callName }
                </Button>
              </Grid>
            </Grid>
          )}
          
          <Grid container spacing={2} justifyContent="center" alignItems="center" p={2}>
            <Grid item md={2} p={2}>
              <Button 
                id="entry-form-summary-add-entry"
                color="primary" 
                variant="contained"
                className="entry-form-summary-button"
                style={{textTransform: 'none'}}
                onClick={(e: any) => handleClickAddEntry(e)}>
                Add New Entry
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} justifyContent="center" alignItems="center" p={2}>
            <Grid item md={2} p={2}>
              <Button 
                id="entry-form-summary-done"
                color="primary" 
                variant="contained"
                disabled={entries.length === 0}
                className="entry-form-summary-button"
                style={{textTransform: 'none'}}
                onClick={handleClickDone}>
                Proceed to Review
              </Button>
            </Grid>
          </Grid>
        </>
        : null
      }
    </>
  )
}
