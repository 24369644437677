import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import './welcomeView.css';
import WelcomeCard from '../../atoms/welcomeCard/welcomeCard';
import axios from 'axios';
import { ConstantsRecord } from '../../../models/ConstantsRecord';
import _ from 'lodash';

export default function WelcomeView() {

  const [ constants, setConstants ] = useState<ConstantsRecord|null>(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const welcomeCardConstants: any = await axios.get(`/api/constants/welcome-cards`);
        setConstants(welcomeCardConstants.data);
      } catch(err: any) {
        setConstants({ _id: 'welcome-cards', _rev: 'na', overviewSections: [], services: [] })
        console.log('get constants err=' + JSON.stringify(err, null, 2));
      }
    }
    fetchData();
  }, []);

  return (
    <>
      <Grid container className="welcome-view-container" gap={2} rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2 }} >
        { _.get(constants, 'overviewSections', []).length > 0 ?
          <>
            <Grid item md={10} sm={10}>
              <h1>Services</h1>
            </Grid>
            <Grid item md={10} sm={10} className="welcome-view-general-overview-card">
              <h2>Overview</h2>
              { _.get(constants, 'overviewSections', []).map((section: any, index: number) => 
                <div key={'section-' + index} className="welcome-view-service-overview">
                  <h3>{section.title}</h3>
                    { section.lines.map((line: string, lineIndex: number) => 
                      <span key={'section-' + index + '-line-' + lineIndex}>{line}</span>
                    )}
                </div>
              )}
            </Grid>
            { _.get(constants, 'services', []).map((cardObj: any, index: number) => 
              <WelcomeCard 
                key={'card-' + index}
                title={cardObj.title} 
                shortDescription={cardObj.shortDescription} 
                detailedDescription={cardObj.detailedDescription}
                links={cardObj.links}/>
            )}
          </>
          : null
        }
      </Grid>
      <div className="welcome-view-bottom">&nbsp;</div>
    </>
  )
}
