import React, { useEffect, useState } from 'react';
import { Autocomplete, Button, Checkbox, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import { Error as ErrorIcon, Warning as WarningIcon, ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { validateOwnerAddressFields, validateDogFields, validateDogJrHandlerFields, validateDogBreedAkcNumFields, validateClassesEntered } from '../../../utils/validateFormFields';
import _ from 'lodash';
import './entryFormData.css';
import { EntryFormRecordData } from '../../../models/EntryFormRecordData';
import { setEdittingIndex, setEntries } from '../../../redux/reducers/entryFormsSlice';
import axios from 'axios';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { EntryFormEventInfo } from '../../../models/EntryFormEventInfo';
import { EntryEventConfig } from '../../../models/EntryEventConfig';
import EntryFormPrices from '../../atoms/entryFormPrices/entryFormPrices';
import { EntryFormEventInfoClass } from '../../../models/EntryFormEventInfoClass';
import HelpDialog from '../../atoms/helpDialog/helpDialog';

const formValidationRecord = {
  classesEntered: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  breed: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  variety: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  sex: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  jrHandlerName: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  jrHandlerNumber: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  callName: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  akcName: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  breeder: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  sire: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  dam: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  akcRegistrationNum: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  owners: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  email: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  phone: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  first: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  last: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  street: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  city: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  state: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  zip: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  dateOfBirth: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  countryOfBirth: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  handler: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  }
};

export default function EntryFormData() {

  const [ isLoading, setIsLoading ] = useState(true);
  const [ entry, setEntry ] = useState<EntryFormRecordData>();
  const [ stateAbbreviations, setStateAbbreviations ] = useState<string[]>([ 'NC' ]);
  const [ akcRegistrationPrefix, setAkcRegistrationPrefix ] = useState<any[]>([]);
  const [ breeds, setBreeds ] = useState<string[]>([ '']);
  const [ breedGroup, setBreedGroup ] = useState<any[]>([]);
  const [ formValidation, setFormValidation ] = useState<any>(formValidationRecord);
  const [ isDirty, setIsDirty ] = useState<boolean>(false);
  const [ numWarnings, setNumWarnings ] = useState<number>(0);
  const [ numErrors, setNumErrors ] = useState<number>(0);

  const { edittingIndex, entries } = useAppSelector((state: any) => state.entryForms);
  const { entryEvents } = useAppSelector((state: any) => state.eventConfig);
  const dispatch = useAppDispatch();

  useEffect(() => {
    async function fetchData() {
      try {
        const stateAbbreviationsRsp = await axios.get(`/api/constants/state-abbreviations`);
        const updatedStateAbbreviations = _.get(stateAbbreviationsRsp, 'data.states', []);
        updatedStateAbbreviations.splice(0, 0, '');
        const akcregistrationPrefixRsp = await axios.get(`/api/constants/akc-registration-prefix`);
        const updatedAkcregistrationPrefix = _.get(akcregistrationPrefixRsp, 'data.groups', []);
        const akcBreedsRsp = await axios.get(`/api/constants/akc-breeds`);
        const breedsRsp: any = _.get(akcBreedsRsp, 'data.breeds', []);
        const updatedBreeds: string[] = [ '' ];
        const updatedBreedGroup: any[] = [];
        breedsRsp.forEach((breed: any) => {
          updatedBreeds.push(breed.name);
          updatedBreedGroup.push(breed);
        });
        setStateAbbreviations(updatedStateAbbreviations);
        setAkcRegistrationPrefix(updatedAkcregistrationPrefix);
        setBreeds(updatedBreeds);
        setBreedGroup(updatedBreedGroup);
        setIsLoading(false);
      } catch(err: any) {
        console.log('get constants akc-breeds err=' + JSON.stringify(err, null, 2));
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (edittingIndex > -1) {
      const updatedEntry = entries[edittingIndex];
      setEntry(updatedEntry);
      let updatedFormValidation = initFormValidationRecord(updatedEntry);
      
      let numClassesEntered = 0;
      _.get(updatedEntry, 'eventEntries', []).forEach((eventEntry: EntryFormEventInfo) => {
        const eventEntryClassesEntered: { name: string, isEntered: boolean}[] = _.filter(eventEntry.classes, val => val.isEntered);
        numClassesEntered += eventEntryClassesEntered.length;
      });
      if (numClassesEntered === 0) {
        updatedFormValidation.classesEntered.isError = true;
        updatedFormValidation.classesEntered.errorMsg = 'Required to enter at least one class';
      }
      updateFormValidation(updatedFormValidation);
    }
  }, [ edittingIndex, entries ]);

  const initFormValidationRecord = (entryForm: any) => {
    const entryFormFields = Object.keys(entryForm);
    const validationFields = Object.keys(formValidationRecord);
    const newFormValidationRecord: any = _.cloneDeep(formValidationRecord);
    entryFormFields.forEach((key: string) => {
      if (validationFields.includes(key)) {
        const updatedFormValidationRecord: any = fieldValidation(formValidation, key, entryForm[`${key}`], entryForm);
        newFormValidationRecord[`${key}`] = updatedFormValidationRecord[`${key}`];
      }
      if (key === 'ownerAddress') {
        const ownerAddressFields = Object.keys(entryForm.ownerAddress);
        ownerAddressFields.forEach((ownerAddressKey: string) => {
          const updatedFormValidationRecord: any = fieldValidation(formValidation, `ownerAddress.${ownerAddressKey}`, entryForm[`${key}`][`${ownerAddressKey}`], entryForm);
          newFormValidationRecord[`${ownerAddressKey}`] = updatedFormValidationRecord[`${ownerAddressKey}`];
        });
      } 
    });
    return newFormValidationRecord;
  }

  const updateFormValidation = (newValue: any) => {
    setFormValidation(newValue);
    const keys = Object.keys(newValue);
    let updatedNumWarnings = 0;
    let updatedNumErrors = 0;
    keys.forEach(key => {
      if (newValue[`${key}`].isWarning) {
        updatedNumWarnings++;
      }
      if (newValue[`${key}`].isError) {
        updatedNumErrors++;
      }
    });
    setNumWarnings(updatedNumWarnings);
    setNumErrors(updatedNumErrors);
  }

  const classEnteredValidation = (updatedFormValidation: any, entry: EntryFormRecordData) => {
    updatedFormValidation.classesEntered.isWarning = false;

    entry.eventEntries.forEach((eventEntry: EntryFormEventInfo) => {
      const classesEntered: string[] = [];
      eventEntry.classes.forEach((eventClass: EntryFormEventInfoClass) => {
        if (eventClass.isEntered) {
          classesEntered.push(eventClass.name);
        }
      });
      updatedFormValidation = validateClassesEntered(updatedFormValidation, classesEntered);
    });
    return updatedFormValidation;
  }

  const getDefaultJumpHt = (newClassEntry: EntryFormEventInfoClass, eventEntries: EntryFormEventInfo[]) => {
    if (newClassEntry.jumpHt) {
      return newClassEntry.jumpHt;
    }
    const isPreferred = newClassEntry.name.indexOf('Preferred') > -1 ? true : false;
    const isRally = newClassEntry.name.indexOf('Rally') > -1 ? true : false;
    let jumpHt = undefined;
    eventEntries.forEach((eventEntry: EntryFormEventInfo) => {
      eventEntry.classes.forEach((e: EntryFormEventInfoClass) => {
        if (e.jumpHt) {
          if (isPreferred && e.name.indexOf('Preferred') > -1) {
            jumpHt = e.jumpHt;
          }
          if (isRally && e.name.indexOf('Rally') > -1) {
            jumpHt = e.jumpHt;
          }
          if (!isRally && !isPreferred && e.name.indexOf('Rally') === -1 && e.name.indexOf('Preferred') === -1) {
            jumpHt = e.jumpHt;
          }
        }
      });
    });
    return jumpHt;
  }

  const setDisabledClasses = (classes: EntryFormEventInfoClass[], classIndex: number) => {
    classes.forEach((e: EntryFormEventInfoClass) => {
      e.isDisabled = false;
    });
    if (classes[classIndex].isEntered && classes[classIndex].name.match(/.* [AB]$/)) {
      const name = classes[classIndex].name;
      const altClassNameSameLevel = name.endsWith('A') ? name.replace(/ A$/, ' B') : name.replace(/ B$/, ' A');
      const altClassIndex = _.findIndex(classes, val => val.name === altClassNameSameLevel);
      if (altClassIndex > -1) {
        classes[altClassIndex].isDisabled = true;
      }
    }
    if (classes[classIndex].isEntered && classes[classIndex].name.match(/(Master)|(Excellent)|(Advanced)|(Utility)|(Open)/)) {
      const novAindex = _.findIndex(classes, val => val.name.indexOf('Novice A') > -1 && val.name !== 'Beginner Novice A');
      const novBindex = _.findIndex(classes, val => val.name.indexOf('Novice B') > -1 && val.name !== 'Beginner Novice B');
      const novPrefindex = _.findIndex(classes, val => val.name.indexOf('Preferred Novice') > -1);
      const intIndex = _.findIndex(classes, val => val.name === 'Rally Intermediate');
      if (novAindex > -1) {
        classes[novAindex].isDisabled = true;
      }
      if (novBindex > -1) {
        classes[novBindex].isDisabled = true;
      }
      if (novPrefindex > -1) {
        classes[novPrefindex].isDisabled = true;
      }
      if (intIndex > -1) {
        classes[intIndex].isDisabled = true;
      }
    }
    return;
  }

  const setClassEntered = (eventIndex: number, classIndex: number, checked: boolean) => {
    const updatedEntry = _.cloneDeep(entry);
    /* istanbul ignore next */
    if (updatedEntry) {
      updatedEntry.eventEntries[eventIndex].classes[classIndex].isEntered = checked;
      setDisabledClasses(updatedEntry.eventEntries[eventIndex].classes, classIndex);
      updatedEntry.eventEntries[eventIndex].classes[classIndex].isEntered = checked;
      updatedEntry.eventEntries[eventIndex].classes[classIndex].jumpHt = getDefaultJumpHt(updatedEntry.eventEntries[eventIndex].classes[classIndex], updatedEntry.eventEntries);

      // calculate entryFee
      updatedEntry.entryFee = 0;
      updatedEntry.eventEntries.forEach((event: EntryFormEventInfo) => {
        let numIsEntered = 0;
        event.classes.forEach((eventClass: EntryFormEventInfoClass) => {
          if (eventClass.isEntered) {
            const specialPricesIndex  = _.findIndex(entryEvents[eventIndex].specialPrices, (val: any) => eventClass.name === val.class);
            if (updatedEntry.jrHandlerName) {
              numIsEntered++;
              if (numIsEntered <= entryEvents[eventIndex].juniorPrices.length) {
                updatedEntry.entryFee += parseFloat(entryEvents[eventIndex].juniorPrices[(numIsEntered - 1)]);
              } else {
                updatedEntry.entryFee += parseFloat(entryEvents[eventIndex].juniorPrices[entryEvents[eventIndex].juniorPrices.length -1]);
              }
            } else if (specialPricesIndex > -1) {
              numIsEntered++;
              const specialPricesThisClass: any = entryEvents[eventIndex].specialPrices[specialPricesIndex];
              if (numIsEntered <= specialPricesThisClass.prices.length) {
                updatedEntry.entryFee += parseFloat(specialPricesThisClass.prices[(numIsEntered - 1)]);
              } else {
                updatedEntry.entryFee += parseFloat(specialPricesThisClass.prices[specialPricesThisClass.prices.length -1]);
              }
            } else {
              numIsEntered++;
              if (numIsEntered <= entryEvents[eventIndex].prices.length) {
                updatedEntry.entryFee += parseFloat(entryEvents[eventIndex].prices[(numIsEntered - 1)]);
              } else {
                updatedEntry.entryFee += parseFloat(entryEvents[eventIndex].prices[entryEvents[eventIndex].prices.length -1]);
              }
            }
          }
        })
      });
      const updatedFormValidation = classEnteredValidation(_.cloneDeep(formValidation), updatedEntry);
      setEntry(updatedEntry);
      setIsDirty(true);
      if (updatedEntry.entryFee === 0) {
        updatedFormValidation.classesEntered.isError = true;
        updatedFormValidation.classesEntered.errorMsg = 'Required to enter at least one class';
      } else {
        updatedFormValidation.classesEntered.isError = false;
        updatedFormValidation.classesEntered.errorMsg = '';
      }
      updateFormValidation(updatedFormValidation);
    }
  }


  const fieldValidation = (formValidation: any, fieldName: string, value: string, updatedEntry: any) => {
    let updatedFormValidation = _.cloneDeep(formValidation);

    if (fieldName === 'breed') {
      updatedFormValidation = validateDogFields(updatedFormValidation, fieldName, value);
    }
    if (fieldName === 'sex') {
      updatedFormValidation = validateDogFields(updatedFormValidation, fieldName, value);
    }
    if (fieldName.startsWith('ownerAddress') || fieldName === 'email') {
      updatedFormValidation = validateOwnerAddressFields(updatedFormValidation, fieldName.replace('ownerAddress.', ''), value);
    } else if (['jrHandlerName', 'jrHandlerNumber'].includes(fieldName)) {
      updatedFormValidation = validateDogJrHandlerFields(updatedFormValidation, fieldName, value, updatedEntry.jrHandlerNumber, updatedEntry.jrHandlerName);
    } else if (['breed', 'akcRegistrationNum'].includes(fieldName)) {
      updatedFormValidation = validateDogBreedAkcNumFields(updatedFormValidation, fieldName, value, updatedEntry.akcRegistrationNum, updatedEntry.breed, breedGroup, akcRegistrationPrefix);
    } else {
      updatedFormValidation = validateDogFields(updatedFormValidation, fieldName, value);
    }
    return updatedFormValidation;
  }

  const handleChangeFieldValue = (fieldName: string, newValue: string) => {
    const updatedEntry: any = _.cloneDeep(entry);
    if (fieldName.indexOf('akcRegistrationNum') > -1) {
      newValue = newValue.toUpperCase();
    }
    if (fieldName.indexOf('ownerAddress') > -1) {
      updatedEntry.ownerAddress[`${fieldName.replace('ownerAddress.', '')}`] = newValue;
    } else {
      updatedEntry[`${fieldName}`] = newValue;
    }
    setEntry(updatedEntry);
    const updatedFormValidation = _.cloneDeep(formValidation);

    updateFormValidation(fieldValidation(updatedFormValidation, fieldName, newValue, updatedEntry));
    setIsDirty(true);
  }

  const handleChangeClassFieldValue = (eventIndex: number, classIndex: number, fieldName: string, newValue: string) => {
    const updatedEntry: any = _.cloneDeep(entry);
    updatedEntry.eventEntries[eventIndex].classes[classIndex][`${fieldName}`] = newValue;

    setEntry(updatedEntry);
    setIsDirty(true);
  }

  const setClassSelector = (index: number) => {
    const updatedEntry: any = _.cloneDeep(entry);
    updatedEntry.eventEntries[index].isOpen = !updatedEntry.eventEntries[index].isOpen;
    setEntry(updatedEntry);
  }

  const getNumberClassesSelected = (index: number) => {
    /* istanbul ignore next */
    const classes = entry ? entry.eventEntries[index].classes : [];
    const numEntered =  _.filter(classes, val => val.isEntered).length;
    return `${numEntered} class${ numEntered === 1 ? '' : 'es'}`;
  }

  const handleClickSaveEntry = () => {
    const updatedEntries = _.cloneDeep(entries);
    updatedEntries[edittingIndex] = _.cloneDeep(entry);
    updatedEntries[edittingIndex].lastSavedDate = new Date().toISOString();
    [ 
      'jrHandlerName', 'jrHandlerNumber', 'akcName', 'callName', 'akcRegistrationNum',
      'dateOfBirth', 'countryOfBirth', 'breeder', 'sire', 'dam', 'owners', 'email', 'handler'
    ].forEach(fieldName => {
      if (updatedEntries[edittingIndex][`${fieldName}`]) {
        updatedEntries[edittingIndex][`${fieldName}`] = updatedEntries[edittingIndex][`${fieldName}`].trim();
      }
    });

    [ 'street', 'city', 'zip' ].forEach(fieldName => {
      updatedEntries[edittingIndex].ownerAddress[`${fieldName}`] = updatedEntries[edittingIndex].ownerAddress[`${fieldName}`].trim();
    });
    dispatch(setEntries(updatedEntries));
    dispatch(setEdittingIndex(-1));
    setIsDirty(false);
  }

  const handleClickCancelEntry = () => {
    const updatedEntries = _.cloneDeep(entries);
    if (updatedEntries[edittingIndex].lastSavedDate === '') {
      updatedEntries.splice(edittingIndex, 1);
      dispatch(setEntries(updatedEntries));
    } else {
      setEntry(updatedEntries[edittingIndex]);
    }
    dispatch(setEdittingIndex(-1));
    setIsDirty(false);
  }

  const hasNoJumps = (name: string) => {
    const rallyHasNoJumps = ['Rally Novice A', 'Rally Novice B', 'Rally Intermediate', 'Rally Choice', 'Rally Novice Team'];
    const obedienceHasNoJumps = ['Beginner Novice A', 'Beginner Novice B', 'Obed Novice A', 'Obed Novice B', 'Preferred Novice', 'Veterans', 'Brace'];
    return rallyHasNoJumps.includes(name) || obedienceHasNoJumps.includes(name);
  }

  return (
    <>
    { isLoading ? 
      <h1>Loading</h1>
    :
    <>
      { edittingIndex === -1 ?
        <div id="no-entry-selected">
          No Entry currently selected
        </div>
        :
        <>
          <Grid key={'entry-form-data-header'} item md={12}>
            <h2>Entry Form</h2>
            <h3>{entryEvents[0].clubName} - {entryEvents[0].location}</h3>
            { entryEvents.map((entryEvent: EntryEventConfig, index: number) =>
              <div key={'entry-event-' + index}>{entryEvent.name} - {entryEvent.eventNum} - {entryEvent.date} {entryEvent.eventType} trial</div>
            )}
            
            <EntryFormPrices />
            
          </Grid>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <>
            { entry?.eventEntries.map((event: EntryFormEventInfo, eventIndex: number) => 
              <Grid item md={12} sm={12} xs={12} key={'event-' + eventIndex}>
                <div className="entry-form-data-class-header">
                  <Tooltip title={ event.isOpen ? 'Collapse' : 'Expand' }>
                    <IconButton 
                      id={'entry-form-data-class-selection-' + eventIndex + '-expand-button'}
                      key={eventIndex}
                      className="entry-form-data-class-selection-button"
                      color="primary" 
                      aria-label="expand/collapse class selection"
                      component="span"
                      onClick={() => setClassSelector(eventIndex)}>
                      { event.isOpen ? 
                        <ExpandLessIcon style={{ fontSize: 18 }} />
                        : <ExpandMoreIcon style={{ fontSize: 18 }} />
                      }
                    </IconButton>
                  </Tooltip>
                  {event.name} - { getNumberClassesSelected(eventIndex) } selected
                  <Button 
                    id="entry-form-update-classes-button"
                    color="primary" 
                    variant="contained"
                    size="small"
                    className="entry-form-update-classes-button"
                    style={{textTransform: 'none'}}
                    onClick={() => setClassSelector(eventIndex)}>
                    { event.isOpen ? 'Close' : 'Update classes entered' } 
                  </Button>
                  { event.isOpen ?
                    <>
                      { event.classes.map((classOffered: EntryFormEventInfoClass, classIndex: number) => 
                        <div className="entry-form-data-class" key={'entry-form-data-class-' + eventIndex + '-' + classIndex}>
                          <Checkbox 
                            checked={classOffered.isEntered}
                            id={'class-' + eventIndex + '-' + classIndex}
                            name={'class-' + eventIndex + '-' + classIndex}
                            onChange={(e: any) => setClassEntered(eventIndex, classIndex, e.target.checked)}
                            disabled={ classOffered.isDisabled }
                            color="primary" />
                          <span id={'class-' + eventIndex + '-' + classIndex + '-' + classOffered.name.replace(/ /g, '-')}>{classOffered.name}</span>
                          { classOffered.isEntered ?
                            <>
                              <FormControl className='entry-form-data-jump-ht'>
                                { classOffered.name.indexOf('Rally') > -1 ?
                                  <>
                                    <InputLabel id="entry-form-data-jump-ht-select-label">Jump Ht</InputLabel>
                                    <Select
                                      id={`class-${eventIndex}-${classIndex}-jump-ht`}
                                      labelId="entry-form-data-jump-ht-select-label"
                                      label="Jump Ht"
                                      value={_.get(classOffered, 'jumpHt', '')}
                                      onChange={(e: any) => handleChangeClassFieldValue(eventIndex, classIndex, 'jumpHt', e.target.value.toString())}>
                                      <MenuItem value=""></MenuItem>
                                      <MenuItem value="4">4</MenuItem>
                                      <MenuItem value="8">8</MenuItem>
                                      <MenuItem value="12">12</MenuItem>
                                      <MenuItem value="16">16</MenuItem>
                                    </Select>
                                  </>
                                  :
                                  <>
                                  <TextField 
                                  id={`class-${eventIndex}-${classIndex}-jump-ht`}
                                  className="entry-form-data-class-field"
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  required
                                  onChange={(e: any) => handleChangeClassFieldValue(eventIndex, classIndex, 'jumpHt', e.target.value.toString())}
                                  value={_.get(classOffered, 'jumpHt', '')}
                                  label='Jump Ht' />
                                  </>
                                }
                              </FormControl>
                              { hasNoJumps(classOffered.name) ?
                                <HelpDialog subject={'entryFormDataJumpHt'} />
                                : null
                              }
                              { classOffered.name === 'Rally Novice Team' ? 
                                <FormControl className='entry-form-data-team-name'>
                                  <TextField 
                                    id={`class-${eventIndex}-${classIndex}-team-name`}
                                    className="entry-form-data-class-field"
                                    variant="outlined"
                                    size="small"
                                    required
                                    onChange={(e: any) => handleChangeClassFieldValue(eventIndex, classIndex, 'teamName', e.target.value.toString())}
                                    value={_.get(classOffered, 'teamName', '')}
                                    label='Team Name' />
                                </FormControl>
                                : null
                              }
                              { classOffered.name === 'Brace' ? 
                                <FormControl className='entry-form-data-team-name'>
                                  <TextField 
                                    id={`class-${eventIndex}-${classIndex}-team-name`}
                                    className="entry-form-data-class-field"
                                    variant="outlined"
                                    size="small"
                                    required
                                    onChange={(e: any) => handleChangeClassFieldValue(eventIndex, classIndex, 'teamName', e.target.value.toString())}
                                    value={_.get(classOffered, 'teamName', '')}
                                    label='Partner AKC #' />
                                </FormControl>
                                : null
                              }
                            </>
                            : null
                          }
                        </div>
                      )}
                    </>
                    : null
                  }
                </div>
              </Grid>
            )}
            { formValidation.classesEntered.isError ?
              <span id="entry-form-data-error-class-entered"><ErrorIcon className="entry-form-data-error-icon" />{ formValidation.classesEntered.errorMsg }</span>
              : 
              <>
                { formValidation.classesEntered.isWarning ?
                  <span id="entry-form-data-warning-class-entered"><WarningIcon className="entry-form-data-warning-icon" />{ formValidation.classesEntered.warningMsg }</span>
                  : null
                }
              </>
            }
            </>
          </Grid>
          <Grid container direction="row">
            <Grid item md={12} xs={12} p={1}>
              <FormControl>
                <TextField 
                  id={'entry-form-data-entry-fee'}
                  variant="outlined"
                  size="small"
                  value={_.get(entry, 'entryFee', 0).toFixed(2)}
                  disabled={true}
                  label='Entry Fee' />
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12} p={1}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  disableClearable
                  id="entry-form-data-breed-select"
                  options={breeds}
                  value={_.get(entry, 'breed', '')}
                  onChange={(e: any, newValue: any) => handleChangeFieldValue('breed', newValue)}
                  renderInput={(params) => <TextField {...params} label="Breed" />} />
                  { formValidation.breed.isError ?
                    <span id="entry-form-data-error-breed"><ErrorIcon className="entry-form-data-error-icon" />{ formValidation.breed.errorMsg }</span>
                    : null
                  }
              </FormControl>
            </Grid>
            <Grid item md={3} xs={12} p={1}>
              <FormControl fullWidth>
                <TextField 
                  id='entry-form-data-variety'
                  variant="outlined"
                  value={_.get(entry, 'variety', '')}
                  onChange={(e: any) => handleChangeFieldValue('variety', e.target.value.toString())}
                  label='Variety (optional)' />
              </FormControl>
            </Grid>
            <Grid item md={2} p={1}>
              <FormControl fullWidth>
                <InputLabel id="entry-form-data-sex-label">Sex</InputLabel>
                <Select
                  id="entry-form-data-sex-select"
                  labelId="entry-form-data-sex-label"
                  label="Sex"
                  style={{width: 200}}
                  value={_.get(entry, 'sex', '')}
                  onChange={(e: any) => handleChangeFieldValue('sex', e.target.value.toString())}>
                  <MenuItem value=""></MenuItem>
                  <MenuItem value="Bitch">Bitch</MenuItem>
                  <MenuItem value="Dog">Dog</MenuItem>
                </Select>
                { formValidation.sex.isError ?
                    <span id="entry-form-data-error-sex"><ErrorIcon className="entry-form-data-error-icon" />{ formValidation.sex.errorMsg }</span>
                    : null
                  }
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12} p={1}>
              <FormControl fullWidth>
                <TextField 
                  id={'entry-form-data-jr-handler-name'}
                  variant="outlined"
                  value={_.get(entry, 'jrHandlerName', '')}
                  onChange={(e: any) => handleChangeFieldValue('jrHandlerName', e.target.value.toString())}
                  label='Name of Junior Handler' />
                { formValidation.jrHandlerName.isWarning ?
                  <span id="entry-form-data-warning-jr-handler-name"><WarningIcon className="entry-form-data-warning-icon" />{ formValidation.jrHandlerName.warningMsg }</span>
                  : null
                }
              </FormControl>
            </Grid>
            <Grid item md={2} xs={12} p={1}>
              <FormControl fullWidth>
                <TextField 
                  id={'entry-form-data-jr-handler-number'}
                  variant="outlined"
                  value={_.get(entry, 'jrHandlerNumber', '')}
                  onChange={(e: any) => handleChangeFieldValue('jrHandlerNumber', e.target.value.toString())}
                  label='Junior Handler Number' />
                { formValidation.jrHandlerNumber.isWarning ?
                  <span id="entry-form-data-warning-jr-handler-number"><WarningIcon className="entry-form-data-warning-icon" />{ formValidation.jrHandlerNumber.warningMsg }</span>
                  : null
                }
              </FormControl>
            </Grid>
            <Grid item md={8} xs={12} p={1}>
              <FormControl fullWidth>
                <TextField 
                  id={'entry-form-data-akc-name'}
                  variant="outlined"
                  error={ formValidation.akcName.isError }
                  value={_.get(entry, 'akcName', '')}
                  onChange={(e: any) => handleChangeFieldValue('akcName', e.target.value.toString())}
                  label='Full Name of Dog (including titles)' />
                { formValidation.akcName.isError ?
                  <span id="entry-form-data-error-akc-name"><ErrorIcon className="entry-form-data-error-icon" />{ formValidation.akcName.errorMsg }</span>
                  : 
                  <>
                    { formValidation.akcName.isWarning ?
                      <span id="entry-form-data-warning-akc-name"><WarningIcon className="entry-form-data-warning-icon" />{ formValidation.akcName.warningMsg }</span>
                      : null
                    }
                  </>
                  }
              </FormControl>
            </Grid>
            <Grid item md={2} xs={12} p={1}>
              <FormControl fullWidth>
                <TextField 
                  id={'entry-form-data-call-name'}
                  variant="outlined"
                  error={ formValidation.callName.isError }
                  required
                  value={_.get(entry, 'callName', '')}
                  onChange={(e: any) => handleChangeFieldValue('callName', e.target.value.toString())}
                  label='Call Name' />
                { formValidation.callName.isError ?
                  <span id="entry-form-data-error-call-name"><ErrorIcon className="entry-form-data-error-icon" />{ formValidation.callName.errorMsg }</span>
                  : null
                }
              </FormControl>
            </Grid>
            <Grid item md={3} xs={12} p={1}>
              <FormControl fullWidth>
                <TextField 
                  id={'entry-form-data-akc-registration-num'}
                  variant="outlined"
                  error={ formValidation.akcRegistrationNum.isError }
                  value={_.get(entry, 'akcRegistrationNum', '')}
                  onChange={(e: any) => handleChangeFieldValue('akcRegistrationNum', e.target.value.toString())}
                  label='AKC Registration Number' />
                { formValidation.akcRegistrationNum.isError ?
                  <span id="entry-form-data-error-akc-registration-num"><ErrorIcon className="entry-form-data-error-icon" />{ formValidation.akcRegistrationNum.errorMsg }</span>
                  : 
                  <>
                    { formValidation.akcRegistrationNum.isWarning ?
                      <span id="entry-form-data-warning-akc-registration-num"><WarningIcon className="entry-form-data-warning-icon" />{ formValidation.akcRegistrationNum.warningMsg }</span>
                      : null
                    }
                  </>
                }
              </FormControl>
            </Grid>
            <Grid item md={2} xs={6} p={1}>
              <FormControl fullWidth>
                <TextField 
                  id={'entry-form-data-date-of-birth'}
                  variant="outlined"
                  value={_.get(entry, 'dateOfBirth', '')}
                  onChange={(e: any) => handleChangeFieldValue('dateOfBirth', e.target.value.toString())}
                  label='Date of Birth (MM/DD/YYYY)' />
                  { formValidation.dateOfBirth.isError ?
                    <span id="entry-form-data-error-date-of-birth"><ErrorIcon className="entry-form-data-error-icon" />{ formValidation.dateOfBirth.errorMsg }</span>
                    : 
                    <>
                      { formValidation.dateOfBirth.isWarning ?
                        <span id="entry-form-data-warning-date-of-birth"><WarningIcon className="entry-form-data-warning-icon" />{ formValidation.dateOfBirth.warningMsg }</span>
                        : null
                      }
                    </>
                  }
              </FormControl>
            </Grid>
            <Grid item md={2} xs={6} p={1}>
              <FormControl fullWidth>
                <TextField 
                  id={'entry-form-data-place-of-birth'}
                  variant="outlined"
                  value={_.get(entry, 'countryOfBirth', '')}
                  onChange={(e: any) => handleChangeFieldValue('countryOfBirth', e.target.value.toString())}
                  label='Place of Birth (list country)' />
              </FormControl>
            </Grid>
            <Grid item md={8} xs={12} p={1}>
              <FormControl fullWidth>
                <TextField 
                  id={'entry-form-data-breeder'}
                  variant="outlined"
                  value={_.get(entry, 'breeder', '')}
                  onChange={(e: any) => handleChangeFieldValue('breeder', e.target.value.toString())}
                  label='Breeder' />
                { formValidation.breeder.isWarning ?
                  <span id="entry-form-data-warning-breeder"><WarningIcon className="entry-form-data-warning-icon" />{ formValidation.breeder.warningMsg }</span>
                  : null
                }
              </FormControl>
            </Grid>
            <Grid item md={8} xs={12} p={1}>
              <FormControl fullWidth>
                <TextField 
                  id={'entry-form-data-sire'}
                  variant="outlined"
                  value={_.get(entry, 'sire', '')}
                  onChange={(e: any) => handleChangeFieldValue('sire', e.target.value.toString())}
                  label='Sire' />
                { formValidation.sire.isWarning ?
                  <span id="entry-form-data-warning-sire"><WarningIcon className="entry-form-data-warning-icon" />{ formValidation.sire.warningMsg }</span>
                  : null
                }
              </FormControl>
            </Grid>
            <Grid item md={8} xs={12} p={1}>
              <FormControl fullWidth>
                <TextField 
                  id={'entry-form-data-dam'}
                  variant="outlined"
                  value={_.get(entry, 'dam', '')}
                  onChange={(e: any) => handleChangeFieldValue('dam', e.target.value.toString())}
                  label='Dam' />
                { formValidation.dam.isWarning ?
                  <span id="entry-form-data-warning-dam"><WarningIcon className="entry-form-data-warning-icon" />{ formValidation.dam.warningMsg }</span>
                  : null
                }
              </FormControl>
            </Grid>
            <Grid item md={8} xs={12} p={1}>
              <FormControl fullWidth>
                <TextField 
                  id={'entry-form-data-owners'}
                  variant="outlined"
                  error={ formValidation.owners.isError }
                  value={_.get(entry, 'owners', '')}
                  onChange={(e: any) => handleChangeFieldValue('owners', e.target.value.toString())}
                  label='Actual Owner' />
                { formValidation.owners.isError ?
                  <span id="entry-form-data-error-owners"><ErrorIcon className="entry-form-data-error-icon" />{ formValidation.owners.errorMsg }</span>
                  : 
                  <>
                    { formValidation.owners.isWarning ?
                      <span id="entry-form-data-warning-owners"><WarningIcon className="entry-form-data-warning-icon" />{ formValidation.owners.warningMsg }</span>
                      : null
                    }
                  </>
                }
              </FormControl>
            </Grid>
            <Grid item md={8} xs={12} p={1}>
              <FormControl fullWidth>
                <TextField 
                  id={'entry-form-data-street'}
                  variant="outlined"
                  error={ formValidation.street.isError }
                  value={_.get(entry, 'ownerAddress.street', '')}
                  onChange={(e: any) => handleChangeFieldValue('ownerAddress.street', e.target.value.toString())}
                  label='Street' />
                { formValidation.street.isError ?
                  <span id="entry-form-data-error-street"><ErrorIcon className="entry-form-data-error-icon" />{ formValidation.street.errorMsg }</span>
                  : 
                  <>
                    { formValidation.street.isWarning ?
                      <span id="entry-form-data-warning-street"><WarningIcon className="entry-form-data-warning-icon" />{ formValidation.street.warningMsg }</span>
                      : null
                    }
                  </>
                }
              </FormControl>
            </Grid>
            <Grid item md={5} xs={8} p={1}>
              <FormControl fullWidth>
                <TextField 
                  id={'entry-form-data-city'}
                  variant="outlined"
                  error={ formValidation.city.isError }
                  value={_.get(entry, 'ownerAddress.city', '')}
                  onChange={(e: any) => handleChangeFieldValue('ownerAddress.city', e.target.value.toString())}
                  label='City' />
                { formValidation.city.isError ?
                  <span id="entry-form-data-error-city"><ErrorIcon className="entry-form-data-error-icon" />{ formValidation.city.errorMsg }</span>
                  : 
                  <>
                    { formValidation.city.isWarning ?
                      <span id="entry-form-data-warning-city"><WarningIcon className="entry-form-data-warning-icon" />{ formValidation.city.warningMsg }</span>
                      : null
                    }
                  </>
                }
              </FormControl>
            </Grid>
            <Grid item lg={1} md={2} xs={4} p={1}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  disableClearable
                  id="entry-form-data-state-select"
                  options={stateAbbreviations}
                  value={_.get(entry, 'ownerAddress.state', '')}
                  onChange={(e: any, newValue: any) => handleChangeFieldValue('ownerAddress.state', newValue.toString())}
                  renderInput={(params) => <TextField {...params} label="State" />} />
                { formValidation.state.isError ?
                    <span id="entry-form-data-error-state"><ErrorIcon className="entry-form-data-error-icon" />{ formValidation.state.errorMsg }</span>
                    : null
                  }
              </FormControl>
            </Grid>
            <Grid item md={2} xs={6} p={1}>
              <FormControl fullWidth>
                <TextField 
                  id={'entry-form-data-zip'}
                  variant="outlined"
                  error={ formValidation.zip.isError }
                  value={_.get(entry, 'ownerAddress.zip', '')}
                  onChange={(e: any) => handleChangeFieldValue('ownerAddress.zip', e.target.value.toString())}
                  label='Zip Code' />
                  { formValidation.zip.isError ?
                    <span id="entry-form-data-error-zip"><ErrorIcon className="entry-form-data-error-icon" />{ formValidation.zip.errorMsg }</span>
                    : 
                    <>
                      { formValidation.zip.isWarning ?
                        <span id="entry-form-data-warning-zip"><WarningIcon className="entry-form-data-warning-icon" />{ formValidation.zip.warningMsg }</span>
                        : null
                      }
                    </>
                  }
              </FormControl>
            </Grid>
            <Grid item md={2} xs={12} p={1}>
              <FormControl fullWidth>
                <TextField 
                  id={'entry-form-data-phone'}
                  variant="outlined"
                  error={ formValidation.phone.isError }
                  value={_.get(entry, 'ownerAddress.phone', '')}
                  onChange={(e: any) => handleChangeFieldValue('ownerAddress.phone', e.target.value.toString())}
                  label='Phone (xxx-xxx-xxxx)' />
                  { formValidation.phone.isError ?
                    <span id="entry-form-data-error-phone"><ErrorIcon className="entry-form-data-error-icon" />{ formValidation.phone.errorMsg }</span>
                    : 
                    <>
                      { formValidation.phone.isWarning ?
                        <span id="entry-form-data-warning-phone"><WarningIcon className="entry-form-data-warning-icon" />{ formValidation.phone.warningMsg }</span>
                        : null
                      }
                    </>
                  }
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12} p={1}>
              <FormControl fullWidth>
                <TextField 
                  id={'entry-form-data-email'}
                  variant="outlined"
                  error={ formValidation.email.isError }
                  value={_.get(entry, 'email', '')}
                  onChange={(e: any) => handleChangeFieldValue('email', e.target.value.toString())}
                  label='Email' />
                  { formValidation.email.isError ?
                    <span id="entry-form-data-error-email"><ErrorIcon className="entry-form-data-error-icon" />{ formValidation.email.errorMsg }</span>
                    : 
                    <>
                      { formValidation.email.isWarning ?
                        <span id="entry-form-data-warning-email"><WarningIcon className="entry-form-data-warning-icon" />{ formValidation.email.warningMsg }</span>
                        : null
                      }
                    </>
                  }
              </FormControl>
            </Grid>
            <Grid item md={8} xs={12} p={1}>
              <FormControl fullWidth>
                <TextField 
                  id={'entry-form-data-handler'}
                  variant="outlined"
                  value={_.get(entry, 'handler', '')}
                  onChange={(e: any) => handleChangeFieldValue('handler', e.target.value.toString())}
                  label='Name of Agent/Handler at Trial (if other than owner)' />
              </FormControl>
            </Grid>
            <Grid key={'entry-form-data-save'} item md={12} xs={12} p={1}>
              <Button 
                id="entry-form-save-entry"
                color="primary" 
                variant="contained"
                className="entry-form-save-button"
                disabled={!isDirty || numErrors > 0}
                style={{textTransform: 'none'}}
                onClick={handleClickSaveEntry}>
                Save and Close { numWarnings > 0 ? ` with ${numWarnings} warnings` : '' } 
              </Button>
              <Button 
                id="entry-form-cancel-entry"
                color="primary" 
                variant="contained"
                className="entry-form-cancel-button"
                style={{textTransform: 'none'}}
                onClick={handleClickCancelEntry}>
                { entry?.lastSavedDate === '' ? 'Abort w/o creating' : 'Cancel' }
              </Button>
            </Grid>
            { numErrors > 0 ?
              <Grid item md={12}>
                <div id="entry-form-data-errors">There { numErrors > 1 ? 'are' : 'is' } { numErrors } error{ numErrors > 1 ? 's' : '' } that must be corrected before you can Save the entry form.</div>
              </Grid>
              : null
            }
          </Grid>
        </>
      }
    </>
  }
  </>
  )
}
