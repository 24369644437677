import React, { useEffect, useState } from 'react';
import { Button, Grid, MenuItem, Select, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setSessions } from '../../../redux/reducers/eventConfigSlice';
import PrintView from '../../molecules/printView/printView';
import _ from 'lodash';
import './adminView.css';
import { ReservationInfo } from '../../../models/ReservationInfo';
import { SessionConfig } from '../../../models/SessionConfig';
import { DataInfo } from '../../../models/DataInfo';
// import { number } from 'prop-types';

interface IAdminView {
  handleSessionSortChange: (sessions: SessionConfig[]) => void
  handleReservationSortChange: (reservations: ReservationInfo[]) => void
}

export interface IPrintableSession {
  sessionIndex: number,
  sessionName: string,
  runIndex: number,
  runName: string,
  sortedReservations: ReservationInfo[]
} 

export interface ISortedSession {
  name: string,
  pricePerRun: number,
  sortOrder: string[],
  runs: string[],
  sortField: string,
  reservations: ReservationInfo[]
}

export interface ISignupTableRegistrant {
  ring: number;
  round: number;
  row: number;
  dogName: string;
  breed: string;
  jumpHt: number;
  name: string;
} 

const emailNewLine = '%0D%0A';

const signupSheetConfig = {
  rounds: [ 1, 2 ],
  jumpHts: [ 4, 8, 12, 16, 20, 24 ],
  rows: [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11 ]
}

export default function AdminView(props: IAdminView) {

  const { title, sessions, signupGranularity, isSortable } = useAppSelector((state: any) => state.eventConfig);
  const { data } = useAppSelector((state: any) => state.eventData);

  const dispatch = useAppDispatch();

  //const [ manualSortOrderAssignment, setManualSortOrderAssignment ] = useState<boolean>(false);
  const [ printable, setPrintable ] = useState<boolean>(false);
  const [ printSignupTable, setPrintSignupTable ] = useState<boolean>(false);
  const [ disableSave, setDisableSave ] = useState<boolean>(true);
  const [ sortedSessions, setSortedSessions ] = useState<ISortedSession[]>([]);
  const [ printableSessions, setPrintableSessions ] = useState<IPrintableSession[]>([]);
  const [ signupTableRegistrants, setSignupTableRegistrants ] = useState<ISignupTableRegistrant[]>([]);

  useEffect(() => {
    const updatedSortedSessions: ISortedSession[] = [];
    sessions.forEach((session: SessionConfig, i: number) => {
      updatedSortedSessions.push({ 
        name: session.name,
        pricePerRun: session.pricePerRun,
        sortOrder: session.sortOrder,
        runs: session.runs,
        sortField: session.sortField,
        reservations: []
      });
    });

    // initially, reservations will be populated in the order that they appear in the data
    data.forEach((dataRecord: DataInfo) => {
      dataRecord.reservations.forEach((reservation: ReservationInfo) => {
        const newReservation = _.cloneDeep(reservation);
        newReservation.id = newReservation.slotIndex;
        newReservation.registrantFirstName = dataRecord.registrant.firstName;
        newReservation.registrantLastName = dataRecord.registrant.lastName;
        newReservation.registrantEmail = dataRecord.registrant.email;
        newReservation.registrantId = dataRecord.registrant.registrantId;
        updatedSortedSessions[reservation.sessionIndex].reservations.push(newReservation);
      });
    });

    updatedSortedSessions.forEach((session, sessionIndex) => {
      session.runs.forEach((run, runIndex) => {
        setSortValues(updatedSortedSessions, session.sortOrder[runIndex], sessionIndex, runIndex);
      });
    });
    setSortedSessions(updatedSortedSessions);
  }, [data, sessions]);

  const setSortValues = (currentSortedSessions: ISortedSession[], sortOrder:  string, sessionIndex: number, runIndex: number) => {
    const reservations = currentSortedSessions[sessionIndex].reservations;
    let sortedReservations = _.cloneDeep(reservations);
    if (sortOrder === 'manual') {
      if (runIndex === 0) {
        sortedReservations = reservations.sort((a: any, b: any) => {
          if (a.sortValues[runIndex] < b.sortValues[runIndex]) {
            return -1;
          }
          return 1;
        });
      }
    } else {
      const numericFields = [ 'jumpHt' ];
      const sortField = currentSortedSessions[sessionIndex].sortField;

      sortedReservations = reservations.sort((a: any, b: any) => {
        const valA = numericFields.includes(sortField) && _.get(a, sortField, '') !== '' ? parseInt(a[sortField].replace('"', '').trim()) : a[sortField];
        const valB = numericFields.includes(sortField) && _.get(b, sortField, '') !== '' ? parseInt(b[sortField].replace('"', '').trim()) : b[sortField];
        if (valA < valB) {
          return sortOrder === 'asc'|| sortOrder === 'manual' ? -1 : 1;
        }
        return sortOrder === 'asc' ? 1 : -1;
      });
      sortedReservations.forEach((reservation: ReservationInfo, i: number) => {
        const index = _.findIndex(reservations, val => val.id === reservation.id);
        reservations[index].sortValues[runIndex] = i + 1;
      });
    }
  }

  const getUpdatedReservations = (updatedSessions: ISortedSession[]) => {
    const reservations: ReservationInfo[] = [];
    const currentData = _.cloneDeep(data);
    updatedSessions.forEach((session: ISortedSession) => {
      session.reservations.forEach((reservation: ReservationInfo) => {
        const dataIndex = _.findIndex(currentData, (val: DataInfo) => val.registrant.firstName === reservation.registrantFirstName && val.registrant.lastName === reservation.registrantLastName && val.registrant.email === reservation.registrantEmail);
        const dataReservationIndex = _.findIndex(currentData[dataIndex].reservations, (val: ReservationInfo) => val.sessionIndex === reservation.sessionIndex && val.slotIndex === reservation.slotIndex);
        if (dataReservationIndex > -1 && !_.isEqual(reservation.sortValues, currentData[dataIndex].reservations[dataReservationIndex].sortValues)) {
          reservations.push(reservation);
        }
      });
    });
    return reservations;
  }

  const handleClickSaveManualSortOrderAssignment = () => {
    props.handleReservationSortChange(getUpdatedReservations(sortedSessions));
    setDisableSave(true);
  }

  const getEntrantEmails = () => {
    const emails: string[] = [];

    sortedSessions.forEach(session => {
      session.reservations.forEach((reservation: ReservationInfo) => {
        const reservationEmail = _.get(reservation, 'registrantEmail', '');
        if (reservation.dogName !== '' && reservationEmail !== '' && !emails.includes(reservationEmail)) {
          emails.push(reservationEmail);
        }
      });
    });
    // return comma separated string
    return emails.toString();
  }

  const calculateRuns = (reservation: ReservationInfo) => {
    let retValue = 1;
    if (signupGranularity === 'run') {
      retValue = 0;
      _.get(reservation, 'runs', []).forEach((numRuns: number) => {
        retValue += numRuns;
      })
    }
    return retValue;
  }

  const getEntrants = () => {
    let entries: {
      registrantFirstName: string;
      registrantLastName: string;
      runs: number;
      amount: number;
    }[] = [];
    sortedSessions.forEach(session => {
      session.reservations.forEach((reservation: ReservationInfo) => {
        if (reservation.dogName !== '') {
          const entriesIndex = _.findIndex(entries, val => val.registrantFirstName === reservation.registrantFirstName && val.registrantLastName === reservation.registrantLastName);
          const numRuns = calculateRuns(reservation);
          if (entriesIndex > -1) {
            entries[entriesIndex].runs += numRuns;
            entries[entriesIndex].amount += (numRuns * session.pricePerRun);
          } else {
            entries.push({
              registrantFirstName: _.get(reservation, 'registrantFirstName', ''),
              registrantLastName: _.get(reservation, 'registrantLastName', ''),
              runs: numRuns,
              amount: numRuns * session.pricePerRun
            });
          }
        }
      });
    });
    const entriesSorted = entries.sort((a, b) => {
      if (a.registrantLastName < b.registrantLastName) {
        return -1;
      }
      return 1;
    });
    return entriesSorted;
  }

  const getTextWithUnderline = (val: string) => {
    let retVal = '';
    for (let i = 0; i < val.length; i++) {
      retVal += '-';
    }
    return val + emailNewLine + retVal;
  }

  const createEmailBody = () => {
    let retString = '';

    // Intro
    retString += 'Hi everyone,' + emailNewLine;
    retString += emailNewLine;
    retString += 'Thanks for entering the ' + title + emailNewLine;
    retString += emailNewLine;
    retString += 'Please find below:' +  emailNewLine;
    retString += '1) a list of entrants along with how many reservations you signed up for and the amount you owe' +  emailNewLine;
    retString += '2) the running order (grouping jump heights and trying to minimize conflicts)';
    retString += emailNewLine;

    // Entrants w/ amount owed
    retString += emailNewLine;
    retString += getTextWithUnderline('Entrants') + emailNewLine;
    getEntrants().forEach(entry => {
      retString += `${entry.registrantFirstName} ${entry.registrantLastName} has ${entry.runs} reservation(s) and owes $${entry.amount}` + emailNewLine;
    });

    retString += emailNewLine;

    // Running order
    retString += emailNewLine;
    sortedSessions.forEach(session => {
      retString += getTextWithUnderline(session.name + ' running order - Standard ring') + emailNewLine;
      let index = 1;
      session.reservations.forEach((reservation: ReservationInfo) => {
        if (reservation.dogName !== '') {
          retString += `${index}) ${reservation.registrantFirstName} ${reservation.registrantLastName} / ${reservation.dogName} : jumping ${reservation.jumpHt}` + emailNewLine;
          index++;
        }
      });
      retString += emailNewLine;
    });
    return retString.replace(/ /g, '%20').replace(/&/g, '%26');
  }

  const handleClickSendEmail = () => {
    window.open('mailto:?bcc=' + getEntrantEmails() + '&' + 
      'subject=' + title + '&' +
      'body=' + createEmailBody())
  }

  const handleChangeSortValue = (event: any, sessionIndex: number, slotIndex: number, runIndex: number) => {
    const updatedSortedSessions = _.cloneDeep(sortedSessions);
    updatedSortedSessions[sessionIndex].reservations[slotIndex].sortValues[runIndex] = event.target.value ? parseInt(event.target.value) : 0;
    setSortedSessions(updatedSortedSessions);
  }

  const handleBlurSortValue = (event: any, sessionIndex: number, slotIndex: number, runIndex: number) => {
    const updatedSortedSessions = _.cloneDeep(sortedSessions);

    updatedSortedSessions[sessionIndex].reservations[slotIndex].sortValues[runIndex] = parseInt(event.target.value);
    setDisableSave(false);

    // the order the slots are shown are based on the 1st run (runOndex = 0), so only re-order if the change was for that run
    if (runIndex === 0) {
      updatedSortedSessions[sessionIndex].reservations = sortedSessions[sessionIndex].reservations.sort((a: ReservationInfo,b: ReservationInfo) => {
        if (_.get(a, 'sortValues[0]', 100) < _.get(b, 'sortValues[0]', 100)) {
          return -1;
        }
        return 1;
      });
    } 
    setSortedSessions(updatedSortedSessions);
  }

  const handleChangeSessionSortOrder = (event: any, sessionIndex: number, runIndex: number) => {
    let sortOrder = 'manual';
    if (event.target.value === 'asc') {
      sortOrder = 'asc';
    }
    if (event.target.value === 'desc') {
      sortOrder = 'desc';
    }
    const updatedSessions = _.cloneDeep(sessions);
    updatedSessions[sessionIndex].sortOrder[runIndex] = sortOrder;
    dispatch(setSessions(updatedSessions));
    props.handleSessionSortChange(updatedSessions);

    const updatedSortedSessions = _.cloneDeep(sortedSessions);
    updatedSortedSessions[sessionIndex].sortOrder[runIndex] = sortOrder;
    setSortValues(updatedSortedSessions, sortOrder, sessionIndex, runIndex);

    setSortedSessions(updatedSortedSessions);
    setDisableSave(false);
    props.handleReservationSortChange(getUpdatedReservations(updatedSortedSessions));
  }

  const handleClickClearSortValues = (sessionIndex: number, runIndex: number) => {
    const updatedSortedSessions = _.cloneDeep(sortedSessions);
    updatedSortedSessions[sessionIndex].reservations.forEach((reservation: ReservationInfo) => {
      if (reservation.sortValues[runIndex] !== -1) {
        reservation.sortValues[runIndex] = 99;
      }
    });
    setSortedSessions(updatedSortedSessions);
  }

  const createPrintableSessions = () => {
    const updatedPrintableSessions: IPrintableSession[] = [];
    sortedSessions.forEach((session: ISortedSession, sessionIndex: number) => {
      session.runs.forEach((run, runIndex) => {
        const sortedReservations = session.reservations.sort((a: ReservationInfo, b: ReservationInfo) => {
          if (a.sortValues[runIndex] < b.sortValues[runIndex]) {
            return -1;
          }
          return 1;
        });
        updatedPrintableSessions.push({
          sessionIndex,
          sessionName: session.name,
          runIndex,
          runName: run,
          sortedReservations: _.cloneDeep(sortedReservations)
        });
      });
    });
    setPrintableSessions(updatedPrintableSessions);
  }

  const assignSignupTable = () => {
    const updatedSignupTableRegistrants: ISignupTableRegistrant[] = [];

    data.forEach((dataElement: DataInfo) => {
      dataElement.reservations.forEach((reservation: ReservationInfo) => {
        reservation.runs.forEach((run: number, runIndex: number) => {
          updatedSignupTableRegistrants.push({
            ring: runIndex,
            round: 1,
            row: 0,
            dogName: reservation.dogName,
            breed: _.get(reservation, 'breed', ''),
            jumpHt: parseInt(reservation.jumpHt.replace('"', "")),
            name: `${dataElement.registrant.firstName} ${dataElement.registrant.lastName}`
          });
        });
      });
    });

    updatedSignupTableRegistrants.forEach((registrant: ISignupTableRegistrant) => {
      const matches = _.filter(updatedSignupTableRegistrants, val => val.ring === registrant.ring && val.dogName === registrant.dogName && val.name === registrant.name && val.jumpHt === registrant.jumpHt);
      if (matches.length > 1) {
        for (let i = 1; i < matches.length; i++) {
          matches[i].round = matches[i-1].round + 1;
        }
      }
    });

    const sortedSignupTableRegistrants = updatedSignupTableRegistrants.sort((a, b) => {
      if (a.ring < b.ring) {
        return -1;
      }
      if (a.ring > b.ring) {
        return 1;
      }
      if (a.round < b.round) {
        return -1;
      }
      if (a.round > b.round) {
        return 1;
      }
      if (a.jumpHt < b.jumpHt) {
        return -1;
      }
      if (a.jumpHt > b.jumpHt) {
        return 1;
      }
      return 1;
    });
    
    let currentJumpHt = -1;
    let currentRow = 1;
    sortedSignupTableRegistrants.forEach((registrant: ISignupTableRegistrant) => {
      if (registrant.jumpHt !== currentJumpHt) {
        currentJumpHt = registrant.jumpHt;
        currentRow = 1;
      }
      registrant.row = currentRow;
      currentRow++;
    });

    setSignupTableRegistrants(sortedSignupTableRegistrants);
  }

  const getSignupTableRegistrant = (ring: number, round: number, jumpHt: number, row: number) => {
    const val = _.find(signupTableRegistrants, val => val.ring === ring && val.round === round && val.jumpHt === jumpHt && val.row === row);
    return val ? `${val.dogName} - ${val.breed} - ${val.name}` : '';
  }
  return (
    <Grid container alignItems="center" alignContent="flex-end" spacing={1}>
      <Grid item md={3} xs={0} className='noprint'></Grid>
      <Grid item md={2} xs={12} className='noprint'>
        <Button 
            id="admin-view-save-sort-button"
            color="primary" 
            variant="contained"
            style={{textTransform: 'none'}} 
            disabled={disableSave}
            onClick={handleClickSaveManualSortOrderAssignment}>
            Save assigned sort values
          </Button>
      </Grid>
      <Grid item md={2} xs={12} className='noprint'>
        <Button 
          id="admin-view-send-email-button"
          color="primary" 
          variant="contained"
          style={{textTransform: 'none'}} 
          onClick={handleClickSendEmail}>
          Send email confirmations
        </Button>
      </Grid>
      <Grid item md={2} xs={12} className='noprint'>
        <Button 
          id="admin-view-print-button"
          color="primary" 
          variant="contained"
          style={{textTransform: 'none'}} 
          onClick={(e: any) => {
            e.preventDefault();
            createPrintableSessions();
            setPrintable(true);
            setTimeout(() => { window.print(); setPrintable(false); }, 100);
            }}>
          Print
        </Button>
      </Grid>
      <Grid item md={2} xs={12} className='noprint'>
        <Button 
          id="admin-view-print-sheets-button"
          color="primary" 
          variant="contained"
          style={{textTransform: 'none'}} 
          onClick={(e: any) => {
            e.preventDefault();
            assignSignupTable();
            setPrintSignupTable(true);
            setTimeout(() => { window.print(); setPrintSignupTable(false); }, 100);
            }}>
          Print Signup Sheets
        </Button>
      </Grid>

      { printSignupTable ?
        <div className="admin-view-signup-table-container">
          { ['STD', 'JWW'].map((ring: string, ringIndex: number) => (
            <React.Fragment key={ringIndex + '-wrapper'}>
              { signupSheetConfig.rounds.map((round: number, roundIndex: number) => (
                <React.Fragment key={ringIndex + '-' + roundIndex + '-wrapper'}>
                  <h2 className="admin-view-round-header">{ring } - Round { round }</h2>
                  { signupSheetConfig.jumpHts.map((jumpHt: number, jumpHtIndex: number ) => (
                    <React.Fragment key={ringIndex + '-' + roundIndex + '-' + jumpHtIndex + '-wrapper'}>
                      <div id={`admin-view-height-header-${jumpHt}`} className="admin-view-height-header">{ring } - Round { round } - Jump Ht: { jumpHt }</div>
                      <table className="admin-view-signup-table">
                        <tbody>
                          { signupSheetConfig.rows.map((row: number, rowIndex: number) => (
                            <React.Fragment key={ringIndex + '-' + roundIndex + '-' + jumpHtIndex + '-' + rowIndex + '-wrapper'}>
                              { jumpHtIndex < 1 && rowIndex > 5 ?
                                null
                                :
                                <tr>
                                  <td className="admin-view-signup-table-cell">{ row }. { getSignupTableRegistrant(ringIndex, round, jumpHt, rowIndex + 1) }</td>
                                </tr>
                              }
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </div>
        :
        <>
          { printable ?
            <PrintView printableSessions={printableSessions} />
            :
            <>
              { isSortable ?
                <>
                  { sortedSessions.map((session: any, sessionIndex: number) => (
                    <React.Fragment key={sessionIndex + '-wrapper'}>
                      <Grid item md={12}>
                        <h2>{session.name}</h2>
                      </Grid>
                      <Grid item md={8}></Grid>
                      { session.runs.map((val: string, runIndex: number) => (
                        <Grid key={runIndex} item md={2}>
                          <span className="admin-view-run-header">{val}</span>
                          <Select
                            labelId="sort-order-select-label"
                            id="sort-order-select"
                            value={session.sortOrder[runIndex]}
                            onChange={(e) => handleChangeSessionSortOrder(e, sessionIndex, runIndex)}
                            >
                            <MenuItem value="asc">asc</MenuItem>
                            <MenuItem value="desc">desc</MenuItem>
                            <MenuItem value="manual">manual</MenuItem>
                          </Select>
                        </Grid>
                      ))}
                      <Grid item md={8}></Grid>
                      { session.runs.map((val: string, runIndex: number) => (
                        <Grid key={runIndex} item md={2}>
                          { session.sortOrder[runIndex].toString() === 'manual' ?
                            <Button 
                              id={`admin-view-clear-sort-${runIndex}`}
                              className="sort-clear"
                              color="primary" 
                              variant="contained"
                              style={{textTransform: 'none'}} 
                              onClick={() => handleClickClearSortValues(sessionIndex, runIndex)}>
                              Clear sort values
                            </Button>
                            : null
                          }
                        </Grid>
                      ))}
                      { session.reservations.map((reservation: ReservationInfo, slotIndex: number) => (
                        <React.Fragment key={sessionIndex + slotIndex + 'wrapper'}>
                          { reservation.dogName === ''
                            ? null
                            : 
                            <>
                              <Grid item md={3} xs={12} p={1}>
                                <span className="admin-view-card-field-label">Name:&nbsp;</span>
                                <span className="admin-view-card-field-name">{reservation.registrantFirstName} {reservation.registrantLastName}</span>
                              </Grid>
                              <Grid item md={2} xs={12} p={1}>
                                <span className="admin-view-card-field-label">Dog:&nbsp;</span>
                                <span className="admin-view-card-field-dog">{reservation.dogName}</span>
                              </Grid>
                              <Grid item md={2} xs={12} p={1}>
                                <span className="admin-view-card-field-label">Breed:&nbsp;</span>
                                <span className="admin-view-card-field-breed">{reservation.breed}</span>
                              </Grid>
                              <Grid item md={1} xs={12} p={1}>
                                <span className="admin-view-card-field-label">Jump:&nbsp;</span>
                                <span className="admin-view-card-field-jumpht">{reservation.jumpHt}</span>
                              </Grid>
                              { reservation.sortValues.map((sortValue: number, runIndex: number) => (
                                <Grid key={runIndex} item md={2} xs={12} p={1}>
                                  <TextField 
                                    id={'slot-sort-value-session-' + sessionIndex + '-slot-' + slotIndex + '-run-' + runIndex}
                                    className="admin-view-slot-sort-value-input"
                                    variant="outlined"
                                    size="small"
                                    disabled={sortValue === -1}
                                    value={sortValue === -1 ? 'Not entered' : sortValue}
                                    onChange={(e) => handleChangeSortValue(e, sessionIndex, slotIndex, runIndex)}
                                    onBlur={(e) => handleBlurSortValue(e, sessionIndex, slotIndex, runIndex)}
                                    label="Sort Value" />
                                </Grid>
                              ))}
                            </>
                          }
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))}
                </>
                : null
              }
            </>
          }
        </>
      }
    </Grid>
  )
}
