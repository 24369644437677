import React from 'react';
import { Button, Grid } from '@mui/material';
import './entryFormReview.css';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { EntryFormEventInfo } from '../../../models/EntryFormEventInfo';
import { EntryFormRecordData } from '../../../models/EntryFormRecordData';
import { setAgreeToAgreementDate, setStepIndex } from '../../../redux/reducers/entryFormsSlice';
import EntryFormAgreement from '../../atoms/entryFormAgreement/entryFormAgreement';
import EntryFormPrint from '../../atoms/entryFormPrint/entryFormPrint';

export default function EntryFormReview() {

  const { entries, agreeToAgreementDate } = useAppSelector((state: any) => state.entryForms);
  const dispatch = useAppDispatch();

  const getClasses = (eventEntries: EntryFormEventInfo[]) => {
    const retValue: string[] = [];
    eventEntries.forEach((eventEntry: EntryFormEventInfo) => {
      eventEntry.classes.forEach((eventEntryClass: any) => {
        if (eventEntryClass.isEntered) {
          retValue.push(`${eventEntry.eventId} : ${eventEntryClass.name} - Jump Ht: ${eventEntryClass.jumpHt ? eventEntryClass.jumpHt : 'blank'}${ eventEntryClass.teamName ? ' Team: ' + eventEntryClass.teamName : ''}`);
        }
      });
    });
    return retValue;
  }
  
  return (
    <>
      <Grid key={'entry-form-data-header'} item md={12} className='noprint'>
        <h2>Review the Data below and go back to the Entry</h2>
      </Grid>
      { entries.length === 0 ?
        <>
          <div id="entry-form-review-no-entries">
            You have no completed entries. Please return to Step 1 to complete and save at least one entry.
          </div>
          <Grid container spacing={2} justifyContent="center" p={2} className='noprint'>
            <Grid item md={4} p={2}>
              <Button 
                id="entry-form-review-fix-data-button-no-entries"
                color="primary" 
                variant="contained"
                style={{textTransform: 'none'}}
                onClick={(e: any) => { dispatch(setStepIndex(0)); dispatch(setAgreeToAgreementDate('')) }}>
                Oopps, Return to Step 1 to add data
              </Button>
            </Grid>
          </Grid>
        </>
        :
        <>
          <Grid container alignItems="center" alignContent="center" justifyContent="center" className='noprint'>
            <Grid item md={12} p={2}>
              <div id="entry-form-review-instructions">
                You have successfully completed {entries.length} entry form{ entries.length > 1 ? 's' : '' }.
                Please review { entries.length > 1 ? 'them' : 'it' } below, and review and click 'I Agree' to the Agreement before proceeding to the 'Looks Good, Proceed to Payment' step. 
                Pay particular attention to the email address and AKC Registration.
              </div>
            </Grid>
            { entries.map((entry: EntryFormRecordData, index: number) => (
              <Grid container direction="column" justifyContent="flex-start" p={2} key={'entry-form-container-' + index}>
                <Grid item className="entry-form-review-dog-header" id={`entry-form-review-entries-${index}-call-name`}>Call Name: { entry.callName ? entry.callName : entry.akcRegistrationNum }</Grid>
                <Grid item id={`entry-form-review-entries-${index}-line-1`}>Breed: { entry.breed ? entry.breed : 'blank' } Variety: { entry.variety  ? entry.variety : 'blank' } Sex: { entry.sex  ? entry.sex : 'blank' }
                </Grid>
                <Grid item id={`entry-form-review-entries-${index}-line-2`}>Junior Handler: { entry.jrHandlerName === '' ? 'blank' : entry.jrHandlerName + '/' + entry.jrHandlerNumber }</Grid>
                <Grid item id={`entry-form-review-entries-${index}-line-3`}>Full AKC Name: { entry.akcName }</Grid>
                <Grid item id={`entry-form-review-entries-${index}-line-4`}>Call Name: { entry.callName ? entry.callName : 'blank' }</Grid>
                <Grid item id={`entry-form-review-entries-${index}-line-5`} className="entry-form-review-bold">AKC Number: { entry.akcRegistrationNum }</Grid>
                <Grid item id={`entry-form-review-entries-${index}-line-6`}>Date of Birth: { entry.dateOfBirth ? entry.dateOfBirth : 'blank' } Country of Birth: { entry.countryOfBirth ? entry.countryOfBirth  : 'blank' }</Grid>
                <Grid item id={`entry-form-review-entries-${index}-line-7`}>Breeder: { entry.breeder ? entry.breeder : 'blank' }</Grid>
                <Grid item id={`entry-form-review-entries-${index}-line-8`}>Sire: { entry.sire ? entry.sire : 'blank' }</Grid>
                <Grid item id={`entry-form-review-entries-${index}-line-9`}>Dam: { entry.dam ? entry.dam : 'blank' }</Grid>
                <Grid item id={`entry-form-review-entries-${index}-line-10`}>Owner(s): { entry.owners }</Grid>
                <Grid item id={`entry-form-review-entries-${index}-line-11`}>Address: { entry.ownerAddress.street ? entry.ownerAddress.street : 'blank' }</Grid>
                <Grid item id={`entry-form-review-entries-${index}-line-12`}>City: { entry.ownerAddress.city ? entry.ownerAddress.city : 'blank' }, State: { entry.ownerAddress.state ? entry.ownerAddress.state : 'blank' } Zip: { entry.ownerAddress.zip ? entry.ownerAddress.zip : 'blank' }</Grid>
                <Grid item id={`entry-form-review-entries-${index}-line-13`}>Phone: { entry.ownerAddress.phone ? entry.ownerAddress.phone : 'blank' }</Grid>
                <Grid item id={`entry-form-review-entries-${index}-line-14`}>Handler: { entry.handler ? entry.handler : 'same as owner' }</Grid>
                <Grid item id={`entry-form-review-entries-${index}-line-15`} className="entry-form-review-bold">Email: { entry.email }</Grid>
                <Grid item> Classes Entered:</Grid>
                { getClasses(entry.eventEntries).map((classEntered: string, classIndex: number) => (
                  <Grid item key={`entry-form-review-entries-${index}-class-${classIndex}`} id={`entry-form-review-entries-${index}-class-${classIndex}`}>{ classEntered }</Grid>
                ))}
                <Grid item id={`entry-form-review-entries-${index}-entry-fee`}>Entry Fee for this dog: ${entry.entryFee.toFixed(2)}</Grid>
              </Grid>
            ))}
          </Grid>
          <Grid container className='noprint'>
            <EntryFormAgreement />
          </Grid>
          <Grid container spacing={2} justifyContent="center" p={2} className='noprint'>
            <Grid item md={4} p={2}>
              <Button 
                id="entry-form-review-fix-data-button"
                color="primary" 
                variant="contained"
                style={{textTransform: 'none'}}
                onClick={(e: any) => { dispatch(setStepIndex(0)); dispatch(setAgreeToAgreementDate('')) }}>
                Oopps, Return to Step 1 to correct data
              </Button>
            </Grid>
            <Grid item md={3} p={2}>
              <Button 
                id="entry-form-review-proceed-button"
                color="primary" 
                variant="contained"
                disabled={agreeToAgreementDate === ''}
                style={{textTransform: 'none'}}
                onClick={(e: any) => dispatch(setStepIndex(2))}>
                Looks Good, Proceed to Payment
              </Button>
            </Grid>
          </Grid>
          <EntryFormPrint />
        </>
      }
    </>
  )
}
