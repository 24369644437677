import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RegistrantConfig } from '../../models/RegistrantConfig';
// import type { RootState } from '../store';

const initialState: RegistrantConfig = {
  firstName: '',
  lastName: '',
  email: '',
  isAdmin: false,
  isAuth: false,
  isAuth0Authenticated: false,
  allowCookies: false,
  showLoginMessage: true,
  jwtToken: '',
  administerEventId: ''
};

export const registrantSlice = createSlice({
  name: 'registrant',
  initialState,
  reducers: {
    setFirstName: (state, action: PayloadAction<string>) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action: PayloadAction<string>) => {
      state.lastName = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setIsAdmin: (state, action: PayloadAction<boolean>) => {
      state.isAdmin = action.payload;
    },
    setIsAuth: (state, action: PayloadAction<boolean>) => {
      state.isAuth = action.payload;
    },
    setIsAuth0Authenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuth0Authenticated = action.payload;
    },
    setAllowCookies: (state, action: PayloadAction<boolean>) => {
      state.allowCookies = action.payload;
    },
    setShowLoginMessage: (state, action: PayloadAction<boolean>) => {
      state.showLoginMessage = action.payload;
    },
    setJwtToken: (state, action: PayloadAction<string>) => {
      state.jwtToken = action.payload;
    },
    setAdministerEventId: (state, action: PayloadAction<string>) => {
      state.administerEventId = action.payload;
    },
    setRegistrant: (state, action: PayloadAction<any>) => {
      if (action.payload.firstName !== undefined) {
        state.firstName = action.payload.firstName;
      }
      if (action.payload.lastName !== undefined) {
        state.lastName = action.payload.lastName;
      }
      if (action.payload.email !== undefined) {
        state.email = action.payload.email;
      }
      if (action.payload.isAuth !== undefined) {
        state.isAuth = action.payload.isAuth;
      }
      if (action.payload.isAdmin !== undefined) {
        state.isAdmin = action.payload.isAdmin;
      }
      if (action.payload.isAuth0Authenticated !== undefined) {
        state.isAuth0Authenticated = action.payload.isAuth0Authenticated;
      }
      if (action.payload.allowCookies !== undefined) {
        state.allowCookies = action.payload.allowCookies;
      }
      if (action.payload.jwtToken !== undefined) {
        state.jwtToken = action.payload.jwtToken;
      }
      if (action.payload.administerEventId !== undefined) {
        state.administerEventId = action.payload.administerEventId;
      }
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setAdministerEventId,
  setAllowCookies,
  setEmail,
  setIsAdmin,
  setIsAuth,
  setIsAuth0Authenticated,
  setJwtToken,
  setFirstName,
  setLastName,
  setRegistrant,
  setShowLoginMessage
} = registrantSlice.actions;

// export const selectRegistrantName = (state: RootState) => state.registrant.name
// export const selectRegistrantEmail = (state: RootState) => state.registrant.email
// export const selectRegistrantAdminPin = (state: RootState) => state.registrant.adminPin

export default registrantSlice.reducer;
