import React from 'react';
import { IPrintableSession } from '../../organisms/adminView/adminView';
import { useAppSelector } from '../../../redux/hooks';
import { ReservationInfo } from '../../../models/ReservationInfo';
import './printView.css';
import _ from 'lodash';

interface IPrintView {
  printableSessions: IPrintableSession[],
}

export default function PrintView(props: IPrintView) {

  const { showFields, singleReservationOnly } = useAppSelector((state: any) => state.eventConfig);

  const getNonEmptyReservations= (sortedReservations: ReservationInfo[], runIndex: number) => {
    if (showFields.includes('age')) {
      return _.filter(sortedReservations, val => val.age && val.age !== null);
    }
    return _.filter(sortedReservations, val => val.dogName !== '' && val.runs[runIndex] > 0);
  }

  const showSession = (index: number) => {
    return singleReservationOnly && index > 0 ? false : true;
  }

  return (
    <>
      { props.printableSessions.map((session: IPrintableSession, index: number) => (
        <React.Fragment key={'session-' + index}>
          { showSession(index) ?
            <div key={'session-' + session.sessionIndex + '-run-' + session.runIndex  + '-wrapper'} className="admin-view-session-wrapper">
              { !singleReservationOnly ?
                <>
                  <h2>{session.sessionName}</h2>
                  <h3>{session.runName}</h3>
                </>
                : null
              }
              <div>
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      { showFields.includes('dogName') ?
                        <th className="dog-name">Dog</th>
                        : null
                      }
                      { showFields.includes('breed') ?
                        <th className="breed">Breed</th>
                        : null
                      }
                      { showFields.includes('jumpHt') ?
                        <th className="jump-ht">Jump Ht</th>
                        : null
                      }
                      { showFields.includes('Std') ?
                        <>
                          <th className="result">Run #1</th>
                          <th className="result">Run #2</th>
                        </>
                        : null
                      }
                      { showFields.includes('city') ?
                        <th className="city">City, State</th>
                        : null
                      }  
                      { showFields.includes('age') ?
                        <th className="age">Age</th>
                        : null
                      }  
                    </tr>
                  </thead>
                  <tbody>
                    { getNonEmptyReservations(session.sortedReservations, session.runIndex).map((val: any) => (
                      <tr key={'session-' + session.sessionIndex + '-run-' + session.runIndex + '-slot-' + val.id + '-row'}>
                        <td className="owner-name">{val.registrantFirstName} {val.registrantLastName}</td>
                        { showFields.includes('dogName') ?
                          <td className="dog-name">{val.dogName}</td>
                          : null
                        }
                        { showFields.includes('breed') ?
                          <td className="breed">{val.breed}</td>
                          : null
                        }
                        { showFields.includes('jumpHt') ?
                          <td className="jump-ht">{val.jumpHt}</td>
                          : null
                        }
                        { showFields.includes('Std') ?
                          <>
                            <td></td>
                            <td></td>
                          </>
                          : null
                        }
                        { showFields.includes('city') ?
                          <td className="city">{val.city}, {val.state}</td>
                          : null
                        }
                        { showFields.includes('age') ?
                          <td className="age">{val.age}</td>
                          : null
                        }
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            : null
          }
        </React.Fragment>
      ))}
    </>
  )
}
