import React from 'react';
import { Button, Grid } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setActiveSessionIndex } from '../../../redux/reducers/eventConfigSlice';
import SessionInfo from '../../molecules/sessionInfo/sessionInfo';
import './sessionsView.css';
import { SessionConfig } from '../../../models/SessionConfig';
import { ReservationInfo } from '../../../models/ReservationInfo';

interface ISessionsView {
  eventId: string,
  saveReservation: (reservation: ReservationInfo) => void
  cancelReservation: (reservation: ReservationInfo) => void
}

export default function SessionsView(props: ISessionsView) {

  const { sessions, activeSessionIndex, signupGranularity, singleReservationOnly } = useAppSelector((state: any) => state.eventConfig);
  const dispatch = useAppDispatch();

  const saveReservation = (reservation: ReservationInfo) => {
    props.saveReservation(reservation);
  }

  const cancelReservation = (reservation: ReservationInfo) => {
    props.cancelReservation(reservation);
  }

  const getSignupGranularityMessage = () => {
    let msg = '';
    if (singleReservationOnly) {
      return msg;
    }
    if (signupGranularity === 'slot') {
      msg = 'For this event, you will be signing up for a specific slot. ' +
      'The event organizer will as much as practical honor the slot order, ' +
      'however the order may be changed to facilitate an efficiently running event for all participants. ' +
      'If you have multiple runs and/or dogs, the running order will try as much as reasonable to minimize conflicts.';
    }
    if (signupGranularity === 'session') {
      msg = 'For this event, you will be signing up for a specific session. ' +
      'The event organizer will arrange the running order within the session to facilitate an efficiently running event for all participants. ' +
      'If you have multiple runs and/or dogs, the running order will try as much as reasonable to minimize conflicts.';
    }
    if (signupGranularity === 'run') {
      msg = 'For this event, you will be signing up for one or more runs. ' +
      'The event organizer will arrange the running order to facilitate an efficiently running event for all participants. ' +
      'If you have multiple runs and/or dogs, the running order will try as much as reasonable to minimize conflicts.';
    }
    return msg;
  }

  const getPricingMessage = () => {
    let msg = '';
    if (sessions[0].pricePerRun > 0) {
      if (signupGranularity === 'slot' || signupGranularity === 'session') {
        msg = `Entry Fees: $${sessions[0].pricePerRun} per slot you reserve.`;
      }
      if (signupGranularity === 'run') {
        msg = `Entry Fees: $${sessions[0].pricePerRun} per run.`;
      }
    }
    return msg;
  }

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <div className="noprint">
        { sessions.length > 1 && sessions.map((session: SessionConfig, index: number) =>
          <Grid item md={12} key={index}>
            <Button 
              id={ 'sessions-view-session-' + index }
              color="primary" 
              variant={index === activeSessionIndex ? "contained" : "outlined"}
              onClick={(e: any) => dispatch(setActiveSessionIndex(index))}>
              {session.name}
            </Button>
          </Grid>
        )}
        <Grid item md={12}>
          <div className="sessions-view-msg">
            { getSignupGranularityMessage() }
          </div>
        </Grid>
        <Grid item md={12}>
          <div id="sessions-view-pricing" className="sessions-view-msg">
            { getPricingMessage() }
          </div>
        </Grid>
        <SessionInfo 
          eventId={props.eventId} 
          saveReservation={saveReservation}
          cancelReservation={cancelReservation} />
      </div>
    </Grid>
  )
}
